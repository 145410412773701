export const ANNOUNCEMENTS_GET_ALL_SUCCESS_RESPONSE = {
  data: [
    {
      id: 'pengumuman123',
      title:
        'Petunjuk Teknis Pengelolaan Dana Bantuan Operasional Sekolah Kinerja dan Dana Bantuan Operasional Sekolah Afirmasi Tahun Anggaran 2021',
      updatedBy: 'Jonh',
      updatedAt: '2021-11-12T07:38:16.794Z',
    },
    {
      id: 'pengumuman234',
      title:
        'SKB 4 Menteri Mengenai Panduan Penyelenggaraan Pembelajaran Di Masa Pandemi Covid-19',
      updatedBy: 'Jane',
      updatedAt: '2021-11-12T07:38:16.794Z',
    },
  ],
  meta: {
    limit: 10,
    offset: 0,
    total: 2,
  },
}

export const ANNOUNCEMENTS_POST_CREATE_SUCCESS_RESPONSE = {
  data: {
    id: 'string',
    name: 'string',
    title: 'string',
    summary: 'string',
    effectiveAt: '2021-11-16T12:06:58.783Z',
    state: 'ANNOUNCEMENT_UNDEFINED',
    docs: [
      {
        id: 'string',
        announcementId: 'string',
        name: 'string',
        fileUrl: 'string',
        fileSize: 'string',
      },
    ],
    surveyLink: 'string',
    createdBy: 'string',
    updatedBy: 'string',
    deletedBy: 'string',
    createdAt: '2021-11-16T12:06:58.783Z',
    updatedAt: '2021-11-16T12:06:58.783Z',
    deletedAt: '2021-11-16T12:06:58.783Z',
  },
  meta: {},
}

export const ANNOUNCEMENTS_HISTORY_RESPONSE = {
  data: {
    idAnnouncement: 'P3J9Rxj6YQ',
    name: 'test',
    changes: [
      {
        id: 'w1BD3v6VQ5',
        idAnnouncement: 'lez9vWx0j2',
        before: [
          {
            key: 'title',
            value: 'title test',
          },
          {
            key: 'state',
            value: '1',
          },
          {
            key: 'effectiveAt',
            value: '2023-05-25 12:00:00 +0700 WIB',
          },
          {
            key: 'name',
            value: 'name test',
          },
          {
            key: 'summary',
            value: '<p>lorem ipsum</p>',
          },
          {
            key: 'surveyLink',
            value: '',
          },
        ],
        after: [
          {
            key: 'title',
            value: 'title test 2',
          },
          {
            key: 'state',
            value: '2',
          },
          {
            key: 'effectiveAt',
            value: '2023-05-25 05:00:00 +0000 UTC',
          },
          {
            key: 'name',
            value: 'name test 2',
          },
          {
            key: 'summary',
            value: '<p>lorem ipsum 2</p>',
          },
          {
            key: 'surveyLink',
            value:
              'https://docs.google.com/forms/d/162z14LpngwWZd_yhLs4xwn8DS0a6YlwyfWEiMJ8L6aAA',
          },
        ],
        createdBy: 'test',
        createdAt: '2023-05-26T00:47:10.471730Z',
      },
      {
        id: 'oWx0b8DZ1a',
        idAnnouncement: 'lez9vWx0j2',
        before: [],
        after: [
          {
            key: 'docURL',
            value:
              "['https://uploads.aum.staging.belajar.id/announcements/65895ad1-24df-42be-a3ac-a25aa3bbfb95-sample.pdf']",
          },
          {
            key: 'sendNotification',
            value: 'false',
          },
          {
            key: 'name',
            value: 'name test',
          },
          {
            key: 'summary',
            value: '<p>lorem ipsum</p>',
          },
          {
            key: 'effectiveAt',
            value: '2023-05-25 05:00:00 +0000 UTC',
          },
          {
            key: 'thumbnailURL',
            value:
              'https://uploads.aum.staging.belajar.id/announcements/9fbb65ba-b83f-4d3d-bbeb-e72ea83d58e0%3D-No_image_available_600_x_200.jpg',
          },
          {
            key: 'categoryName',
            value: 'Webinar',
          },
          {
            key: 'title',
            value: 'title test',
          },
          {
            key: 'surveyLink',
            value: '',
          },
          {
            key: 'state',
            value: '1',
          },
          {
            key: 'thumbnailName',
            value: 'No_image_available_600_x_200.jpg',
          },
        ],
        createdBy: 'test',
        createdAt: '2023-05-26T00:42:15.960707Z',
      },
    ],
  },
  meta: {
    limit: 10,
    offset: 0,
    total: 2,
  },
}
