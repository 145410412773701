import roles from '../roles'

type MenuType = {
  description: string
  items: {
    title: string
    description: string
    icon: string
    path: string
    permissions: string[]
  }[]
}
const menus: MenuType[] = [
  {
    description: 'Konten umum platform merdeka mengajar',
    items: [
      {
        title: 'Manajemen Info Terkini',
        description:
          'Untuk membuat pengumuman terkait kebijakan atau info baru lainnya dari Kemendikbudristek, agar ditampilkan di platform Merdeka Mengajar.',
        icon: 'menu-announcement',
        path: '/announcements',
        permissions: [roles.CORE_NEWS_EDITOR],
      },
    ],
  },
]

export default menus
