const REGEX_ID_PHONE_NUMBER = /^08\d{6,11}$/
const REGEXS_PHONE_NUMBER = [REGEX_ID_PHONE_NUMBER]
export const REGEX_EMAIL_FORMAT =
  // eslint-disable-next-line security/detect-unsafe-regex
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

export const matchYoutubeUrl = (url) => {
  var p =
    // eslint-disable-next-line security/detect-unsafe-regex
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
  if (url.match(p)) {
    return url.match(p)[1]
  }
  return false
}

export const matchRumahBelajarVideoUrl = (url) => {
  var p =
    // eslint-disable-next-line security/detect-unsafe-regex
    /^(?:https?:\/\/)?rest-app.belajar.kemdikbud.go.id\/files\/video\/([a-zA-Z0-9]+\.mp4)$/

  if (url.match(p)) {
    return url.match(p)[0]
  }
  return false
}

export const validPhoneNumber = (value) => {
  // eslint-disable-next-line security/detect-non-literal-regexp
  const regexPhone = new RegExp(
    REGEXS_PHONE_NUMBER.map((r) => r.source).join('|')
  )
  return regexPhone.test(value)
}

export const checkIfContainsHTML = (value: string) => {
  return /<\/?[a-z][\s\S]*>/i.test(value)
}

export const checkIfContainsListTag = (value: string) => {
  return /<\/?ul>/i.test(value)
}
