import { ToolkitType, ToolkitIdentifier } from 'types/toolkits'

const PAUD_CLASSES = ['PAUD']
const SD_CLASSES = ['1', '2', '3', '4', '5', '6']
const SMP_CLASSES = ['7', '8', '9']
const SMA_CLASSES = ['10', '11', '12']

const PAUD_MINUTES = 30
const SD_MINUTES = 35
const SMP_MINUTES = 40
const SMA_MINUTES = 45

export const MINUTES_SELECTIONS = [30, 35, 40, 45, 60]

export const phaseMentalAgeMap = {
  Fondasi: '≤ 6 tahun',
  A: '≤ 7 tahun',
  B: '± 8 tahun',
  C: '± 8 tahun',
  D: '± 9 tahun',
  E: '± 10 tahun',
  F: '± 10 tahun',
}

export const schoolLevelClassMap = {
  PAUD: PAUD_CLASSES,
  PAUDLB: PAUD_CLASSES,
  SD: SD_CLASSES,
  SDLB: SD_CLASSES,
  SMP: SMP_CLASSES,
  SMPLB: SMP_CLASSES,
  SMA: SMA_CLASSES,
  SMK: SMA_CLASSES,
  SMALB: SMA_CLASSES,
}

export const schoolLevelMinutesMap = {
  PAUD: PAUD_MINUTES,
  PAUDLB: PAUD_MINUTES,
  SD: SD_MINUTES,
  SDLB: SD_MINUTES,
  SMP: SMP_MINUTES,
  SMPLB: SMP_MINUTES,
  SMA: SMA_MINUTES,
  SMK: SMA_MINUTES,
  SMALB: SMA_MINUTES,
}

export const TITLES: Record<ToolkitType, string> = {
  modul_ajar: 'Modul Ajar',
  bahan_ajar: 'Bahan Ajar',
  modul_projek: 'Modul Projek',
}

export const TOOLKIT_TYPES: Record<ToolkitType, string> = {
  modul_ajar: 'Modul Ajar/RPP+',
  bahan_ajar: 'Bahan Ajar',
  modul_projek: 'Modul Projek',
}

export const PANDUAN_LINK_MAPPING: Record<ToolkitType, string> = {
  modul_ajar:
    'https://ruangkolaborasi.zendesk.com/hc/en-us/sections/4940610888089-Unggah-Modul-Ajar',
  bahan_ajar:
    'https://ruangkolaborasi.zendesk.com/hc/en-us/sections/4940594710681-Unggah-Bahan-Ajar',
  modul_projek:
    'https://ruangkolaborasi.zendesk.com/hc/en-us/articles/5010262420633-Contoh-Modul-Projekk',
}

export const TEXTS: Record<ToolkitType, string> = {
  modul_ajar:
    'Bantu pendidik mengajar dengan tepat sasaran, sistematis, dan menarik–untuk mendukung pencapaian kompetensi peserta didik.',
  bahan_ajar:
    'Bantu pendidik menjelaskan suatu pokok bahasan secara komprehensif dengan menyediakan materi pendukung pengajaran.',
  modul_projek:
    'Bantu pendidik melaksanakan pembelajaran berbasis projek dalam upaya pencapaian Profil Pelajar Pancasila pada peserta didik.',
}

export const TOOLKIT_TYPE_PATH_MAP: Partial<
  Record<ToolkitType | ToolkitIdentifier, string>
> = {
  modul_ajar: 'modul-ajar',
  'Modul Ajar/RPP+': 'modul-ajar',
  bahan_ajar: 'bahan-ajar',
  'Bahan Ajar': 'bahan-ajar',
  modul_projek: 'modul-projek',
  'Modul Projek': 'modul-projek',
}

export const MEETING_LIST_SUPPORT_MATERIAL_TYPE_MAPPING: Record<
  string,
  string
> = {
  youtube: 'youtube url',
  'youtube url': 'youtube url',
  'rumah belajar': 'rumah belajar',
  file: 'document',
  document: 'document',
  image: 'image',
  pdf: 'document',
  url: 'website url',
  link: 'website url',
  'website url': 'website url',
}

export const MEETING_LIST_SUPPORT_MATERIAL_ICON_MAPPING: Record<
  string,
  string
> = {
  pdf: 'PDF.png',
  image: 'IMG.png',
  'website url': 'Link.png',
  link: 'Link.png',
  file: 'File.png',
}

type TJenjang = 'paud' | 'sd' | 'smp' | 'sma' | 'smk'

export const jenjangList: TJenjang[] = ['paud', 'sd', 'smp', 'sma', 'smk']

export const modulBahanAjarLinks: Partial<Record<TJenjang, string>> = {
  paud: '3',
  sd: '11',
  smp: '12',
  sma: '13',
}

export const modulProjekLinks: Record<TJenjang, string> = {
  paud: '19',
  sd: '14',
  smp: '16',
  sma: '15',
  smk: '17',
}
