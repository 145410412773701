/**
 * Concatenates an array of strings into a string, delimited with commas (,) with inclusion of the 'Oxford comma'
 *
 * @example
 * // returns "Geometri"
 * oxfordConcat(['Geometri'])
 * @example
 * // returns "Geometri dan Aljabar"
 * oxfordConcat(['Geometri', 'Aljabar'])
 * @example
 * // returns "Geometri, Aljabar, dan Kalkulus"
 * oxfordConcat(['Geometri', 'Aljabar', 'Kalkulus'])
 *
 * @param {string[]} texts - Array of strings to process
 * @param {string} [conjunction=dan] - Conjunction to insert before the last item
 * @returns {string} The concatenated string
 */
export function oxfordConcat(texts: string[], conjunction = 'dan') {
  const len = texts.length

  if (len <= 0) return ''
  if (len < 2) return texts[0]
  if (len < 3) return texts.join(` ${conjunction} `)

  let result = texts.slice()
  result[len - 1] = `${conjunction} ${texts[len - 1]}`

  return result.join(', ')
}

export function titleize(text: string): string {
  return text?.charAt(0)?.toUpperCase?.() + text?.slice?.(1)
}

export function capitalize(text: string): string {
  return text
    ?.split(' ')
    ?.map((word) => titleize(word))
    ?.join(' ')
}

export function prependWithDot(text: string): string {
  if (text == '') {
    return '• '
  } else if (text == '•') {
    return ''
  } else {
    return text.includes('• ') ? text : text.replace(/^/, '• ')
  }
}

export const alphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]

export const addListTypeClass = (value: string) => {
  return value.replace('<ul>', '<ul class="list-disc ml-6 mt-1">')
}
