import roles from '../roles'

export const permissions = {
  '/modul-ajar': [roles.TOOLKIT_ADMIN, roles.TOOLKIT_CONTRIBUTOR],
  '/users': {
    roles: [roles.ADMIN, roles.TOOLKIT_ADMIN, roles.ADMIN_VIEW_ONLY],
    actions: {
      create: [roles.ADMIN],
    },
  },
  '/users/add': {
    roles: [roles.ADMIN],
  },
  '/users-history': {
    roles: [roles.ADMIN],
  },
  '/announcements': [roles.CORE_NEWS_EDITOR],
  '/ide-praktik': [roles.ADMIN_INSPIRATION_EDITOR],
}
