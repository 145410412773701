import React from 'react'
import { useRouter } from 'next/router'

import { useGlobalContext } from 'pages/_app'
import { UNPROTECTED_ROUTES } from 'configs/routes'
import { useAPIGetCurrentUserData } from 'api/users'

const UserDataFetcher = () => {
  const router = useRouter()
  const { route } = router
  const [refetchCount, setRefetchCount] = React.useState<number>(0)
  const { setUserData, setRefetchUserData } = useGlobalContext()
  const isFetchUserDataEnabled = !UNPROTECTED_ROUTES.includes(route)

  const { data } = useAPIGetCurrentUserData({
    opts: { enabled: isFetchUserDataEnabled },
    queryKey: refetchCount,
  })

  React.useEffect(() => {
    if (data?.data) {
      setUserData({
        ...data?.data,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, refetchCount])

  React.useEffect(() => {
    setRefetchUserData(() => async () => {
      setRefetchCount(refetchCount + 1)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div></div>
}
export default UserDataFetcher
