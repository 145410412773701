import create, { State } from 'zustand'

import { titleize } from 'utils/text'

import type { UserSession } from 'types/users'

const ORG_GROUP_FLAG = 'GT_PARTNER'
const FALLBACK_ORG_NAME = 'Kontributor Individual'

export interface UserStates extends State {
  user: UserSession | null
  setUser: (user: UserSession | null) => void
  getUser: () => UserSession
  getUserGroupNames: () => string
}

export const useUserStore = create<UserStates>((set, get) => ({
  user: null,
  setUser: (user) => set(() => ({ user })),
  getUser: () => get().user,
  getUserGroupNames: () => {
    return Object.values(get().user?.user?.groups ?? [])
      .map(({ name }) =>
        name
          .split('-')
          .map((word) => titleize(word))
          .join(' ')
      )
      .join(' · ')
  },
}))

/**
 * Derived state to get current user's array of group names
 * @returns {string[]} List of the current user's group names
 */
export const useCurrentUserRoles = () =>
  useUserStore((state) => {
    return (state.user?.user?.groups ?? []).map(({ name }) => name)
  })

/**
 * Derived state to get current user's organization/community name
 * @returns {string} Current user's organization/community name
 */
export const useCurrentUserOrganization = () =>
  useUserStore(
    (state) =>
      state.user?.user?.groups?.find((group) => group.type === ORG_GROUP_FLAG)
        ?.name ?? FALLBACK_ORG_NAME
  )
