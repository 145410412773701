import { Icon } from '@wartek-id/icon'
import { Text } from '@wartek-id/text'

export const MobileNavInfo = () => {
  return (
    <>
      <div className="w-full p-4 bg-surface-informational-subdued flex items-center space-x-4">
        <Icon as="i" fontSize="small" color="informational">
          desktop_windows
        </Icon>
        <Text variant="body-sm" className="!font-bold">
          Gunakan komputer/laptop untuk menavigasi Ruang Kolaborasi dengan lebih
          mudah.
        </Text>
      </div>
    </>
  )
}
