import { useRouter } from 'next/router'

const REFERRER_STORAGE_KEY = 'guru-cms-referrer'

export const useReferrer = () => {
  const router = useRouter()

  const setReferrer = (value: string) => {
    window.localStorage.setItem(REFERRER_STORAGE_KEY, value)
  }

  const redirectToReferrer = (fallbackValue: string): void => {
    const referrer = window.localStorage.getItem(REFERRER_STORAGE_KEY)

    if (!!referrer) {
      window.localStorage.removeItem(REFERRER_STORAGE_KEY)
      router.replace(referrer)
      return
    }

    router.replace(fallbackValue)
  }

  return { setReferrer, redirectToReferrer }
}
