import { AnyFactories, AnyModels } from 'miragejs/-types'
import { Server, Registry } from 'miragejs'

import { baseURL } from 'configs/api'
import { LOGOUT_POST_SUCCESS_RESPONSE } from './data'
import { setResponse } from 'utils/mirage'

export function logoutHandlers(
  server: Server<Registry<AnyModels, AnyFactories>>
) {
  server.post(
    `${baseURL}/teachers/v1alpha2/logout`,
    setResponse(() => LOGOUT_POST_SUCCESS_RESPONSE)
  )
}
