const roles = {
  ADMIN: 'admin',
  MENGAJAR_ASSESTMENT_ADMIN: 'mengajar.assessment.admin',
  MENGAJAR_TOOLKIT_CONTRIBUTOR: 'mengajar.toolkit.contributor',
  ASSESSMENT_ADMIN: 'admin-assessment',
  ASSESSMENT_CONTRIBUTOR: 'contributor-assessment',
  ASSESSMENT_CURATOR: 'curator-assessment',
  TOOLKIT_ADMIN: 'admin-toolkit',
  TOOLKIT_CONTRIBUTOR: 'contributor-toolkit',
  TOOLKIT_CURATOR: 'curator-toolkit',
  CORE_NEWS_EDITOR: 'core-news-editor',
  ADMIN_VIEW_ONLY: 'admin-view-only',
  ADMIN_INSPIRATION_EDITOR: 'admin-inspiration-editor',
} as const

export default roles
