export const LOGIN_POST_SUCCESS_RESPONSE = {
  data: {
    guruToken: 'bdlfghdfkijshdfliushdfkjbgsdf',
    expiredAt: '2024-01-01T00:00:00Z',
    user: {
      id: 'ff45gf',
      name: 'nama',
      email: 'email@gmail.com',
      picture: '-',
      attributes: [
        {
          key: 'gender',
          value: 'male',
        },
      ],
      groups: [
        {
          id: 'kjsdldfj',
          name: 'admin',
        },
        {
          id: 'kjsdldfj',
          name: 'contributor-toolkit',
        },
        {
          id: 'kjsdldfj',
          name: 'assessment-admin',
        },
      ],
      school: {
        id: 'idsufh9df',
        name: 'SMA Negeri 1 Jakarta',
      },
    },
  },
  meta: null,
}

export const LOGIN_POST_TOKEN_EXPIRED_ERROR_RESPONSE = {
  code: 3,
  message: 'idtoken: token expired',
  details: [
    {
      '@type':
        'type.googleapis.com/wartek.guru.authentication.v1alpha1.AuthenticationError',
      errorCode: 'BAD_REQUEST',
    },
  ],
}

export const LOGIN_POST_TOKEN_INVALID_ERROR_RESPONSE = {
  code: 3,
  message: 'invalid token',
  details: [
    {
      '@type': 'type.googleapis.com/google.rpc.BadRequest',
      fieldViolations: [
        {
          field: 'token',
          description: 'invalid token',
        },
      ],
    },
  ],
}

export const LOGIN_POST_TOKEN_NOT_FOUND_ERROR_RESPONSE = {
  code: 5,
  message: 'user not found',
  details: [
    {
      '@type':
        'type.googleapis.com/wartek.guru.user_management.v1alpha1.UserManagementError',
      errorCode: 'NOT_FOUND',
    },
  ],
}
