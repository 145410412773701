import Cookies from 'js-cookie'

export function getCookie(cookieName: string) {
  return Cookies.get(cookieName)
}

export function setCookie(cookieName: string, cookieValue: string) {
  Cookies.set(cookieName, cookieValue, { expires: 365 })
}

export function removeCookie(cookieName: string) {
  Cookies.remove(cookieName)
}
