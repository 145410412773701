export const GET_BAHAN_AJAR_BY_ID_SUCCESSFULL_RESPONSE = {
  data: {
    id: 'LXmN2VzNpk',
    title: 'Mengukur Bangun Datar',
    author: 'Febriandrini Kumala',
    authors: ['Febriandrini Kumala'],
    source: 'Kontributor Individual',
    school_level: 'SD/sederajat',
    class: 'Kelas 5',
    classes: ['Kelas 5', 'Kelas 6'],
    allocated_time: 140,
    subject: 'Matematika',
    pancasila_student_profiles: ['Kreatif', 'Bernalar Kritis', 'Gotong Royong'],
    has_enrichment: false,
    has_alternative: false,
    learning_models: ['Tatap Muka', 'PJJ Luring', 'PJJ Daring'],
    phase: 'C',
    domains: ['Geometri', 'Aljabar', 'Bilangan', 'Pengukuran'],
    objectives: ['persegi\npersegi panjang\nlingkaran'],
    reference_code: 'SD.C.MAT.ENA.1',
    reference_url:
      'https://storage.googleapis.com/toolkit-public-staging/SD.C.MAT.ENA.1.pdf',
    file_link: '',
    image_url:
      'https://storage.googleapis.com/toolkit-public-staging/6abb2387-3802-48f5-8774-2815e004ed2e___Hello_kitty_character_portrait.png',
    medias: [],
    like_count: 0,
    view_count: 0,
    download_count: 0,
    size: 0,
    image_size: 13178,
    toolkit_type: 'Modul Ajar/RPP+',
    licensed: false,
    student_types: ['Murid berpencapaian tinggi'],
    total_students: 0,
    facilities: '',
    prerequisite: '',
    state: 'Diterbitkan',
    created_by: 'kontributor.cms.toolkit@testing.belajar.id',
    curated: false,
    curated_by: '',
    description:
      'Deskripsi umum yang menggambarkan kegiatan pembelajaran yang akan dilakukan.',
    general_reference: 'Erlangga',
    updated_at: '2021-12-08T06:32:37.670195Z',
    creator_school: '',
  },
  meta: {},
}

export const GET_MEETINGS_SUCCESS_RESPONSE = {
  data: [
    {
      id: 'MZl5QV1OJD',
      title: 'Pertemuan 1 - bangun datar',
      allocated_time: '70',
      size: 487537,
      file_url:
        'https://storage.googleapis.com/toolkit-public-staging/64f3a822-428e-4ba8-a92b-31f0a0bbbeec%3D-Matematika%20Kelas%207.pdf',
      target: '',
      references: [
        {
          id: 'L6NwoEoNBp',
          size: 0,
          url: 'https://www.youtube.com/watch?v=hw6t7xBzy6o',
          type: 'youtube url',
          targets: ['Untuk guru'],
        },
      ],
      exercises: [],
      reflection_instruments: [],
    },
    {
      id: '2XAOM6wKeV',
      title: 'Pertemuan 2 - Mengukur',
      allocated_time: '70',
      size: 487336,
      file_url:
        'https://storage.googleapis.com/toolkit-public-staging/7747cfe8-e750-45ad-bb55-76365991d057%3D-BG%20Bahasa%20Inggris%20Kelas%207%20Web%20%28Bab%203%29%20Maecenas%20vulputate%20sapien%20risus%2C%20a%20pulvinar%20dui%20ultricies%20nec%20-%20Copy.pdf',
      target: '',
      references: [],
      exercises: [
        {
          id: 'XmN27y15pk',
          size: 487336,
          url: 'https://storage.googleapis.com/toolkit-public-staging/a754d033-1d2e-4b9b-a78e-285de8a2a204=-BG%20Bahasa%20Inggris%20Kelas%207%20Web%20%28Bab%204%29%20Maecenas%20vulputate%20sapien%20risus%2C%20a%20pulvinar%20dui%20ultricies%20nec.pdf',
          type: 'document',
          targets: ['Untuk murid'],
        },
        {
          id: 'epNyykDNDV',
          size: 93323,
          url: 'https://storage.googleapis.com/toolkit-public-staging/9ed9b144-714a-401f-8e6c-76d3182a6115=-Seni%20Tari-BG-Kls%20I-Cover%20Thumbnail%20-Lorem%20ipsum%20dolor%20sit%20amet%2C%20consectetur%20adipiscing%20elit.jpg',
          type: 'image',
          targets: ['Untuk guru'],
        },
      ],
      reflection_instruments: [],
    },
  ],
  meta: {
    total: 2,
  },
}
