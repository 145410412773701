import { AnyFactories, AnyModels } from 'miragejs/-types'
import { Server, Registry } from 'miragejs'

import { baseURL } from 'configs/api'
import {
  GET_BAHAN_AJAR_BY_ID_SUCCESSFULL_RESPONSE,
  GET_MEETINGS_SUCCESS_RESPONSE,
} from './data'
import { setResponse } from 'utils/mirage'

export function toolkitsHandlers(
  server: Server<Registry<AnyModels, AnyFactories>>
) {
  server.get(
    `${baseURL}/curriculums/toolkits/:id`,
    setResponse(() => GET_BAHAN_AJAR_BY_ID_SUCCESSFULL_RESPONSE)
  )

  server.get(
    `${baseURL}/curriculums/toolkits/:id/meetings`,
    setResponse(() => GET_MEETINGS_SUCCESS_RESPONSE)
  )
}
