import React from 'react'
import clsx from 'clsx'

import { Spinner } from '@wartek-id/spinner'
import { Text } from '@wartek-id/text'

import Layout from 'components/Layout'

interface LoadingStateProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  className?: string
  title?: string
  description?: string
}

interface LoadingPageStateProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string
  description?: string
}

export function LoadingState({
  children,
  className,
  title,
  description,
  ...props
}: LoadingStateProps) {
  const containerClasses = clsx('flex flex-col justify-center', className)

  return (
    <div {...props} className={containerClasses}>
      <div className="flex justify-center mb-8">
        <Spinner size="lg" />
      </div>

      {children && <div>{children}</div>}

      {!children && (
        <div className="text-center">
          <Text className="mb-2" color="default" variant="display-sm">
            {title}
          </Text>
          <Text className="mb-4" color="default" variant="body">
            {description}
          </Text>
        </div>
      )}
    </div>
  )
}

export function LoadingPageState({
  title = 'Membuka halaman...',
  description = 'Mohon tunggu sebentar',
}: LoadingPageStateProps) {
  return (
    <Layout
      sidebar={() => null}
      hideSidebar
      bgClassName="bg-default"
      className="flex items-center"
    >
      <LoadingState
        className={'w-full'}
        title={title}
        description={description}
      />
    </Layout>
  )
}
