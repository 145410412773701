export const REGISTER_POST_SUCCESS_RESPONSE = {}

export const REGISTER_POST_ALREADY_APPROVED_ERROR_RESPONSE = {
  errors: [
    {
      code: '02-025',
      message: 'User already exist',
    },
  ],
  meta: null,
}

export const REGISTER_POST_ALREADY_SUBMITTED_RESPONSE = {
  errors: [
    {
      code: '02-053',
      message: 'User already registered and still being reviewed',
    },
  ],
  meta: null,
}

export const REGISTER_POST_DRAFT_RESPONSE = {
  errors: [
    {
      code: '02-051',
      message: 'User already registered and still drafting',
    },
  ],
  meta: null,
}

export const REGISTER_PRECREATE_GET_SUCCESS_RESPONSE = {
  data: {
    contributor_type: 'institusi',
    institution_name: 'PT. ABC',
    institution_category: 'Perguruan tinggi',
    institution_legal: {
      id: 'kqKQZ5op0D',
      file_size: 23084,
      file_name:
        'https://uploads.belajar.id/announcements/Infografis%20-%20Platform%20Merdeka%20Mengajar.pdf',
      file_type: 'document',
      document_type: 'legal_document',
      url: '',
      file_extension: 'pdf',
    },
    institution_profile_url: 'https://google.com',
    institution_profile_file: {
      id: 'kqKQZ5op0D',
      file_size: 23084,
      file_name:
        'https://uploads.belajar.id/announcements/Infografis%20-%20Platform%20Merdeka%20Mengajar.pdf',
      file_type: 'document',
      document_type: 'institution_profile',
      url: '',
      file_extension: 'pdf',
    },
    occupation: 'Software Engineer',
    person_name: 'Deny',
    phone: '08992357',
    work_samples: [
      {
        id: 'kqKQZ5op0D',
        file_size: 23084,
        file_name: 'sample-pdf-1-mb.pdf',
        file_type: 'document',
        document_type: 'work_sample',
        url: '',
        file_extension: 'pdf',
      },
      {
        id: '7kKQE6OeDl',
        file_size: 0,
        file_name: '',
        file_type: 'url',
        document_type: 'work_sample',
        url: 'http://url.local',
        file_extension: '',
      },
    ],
    individual_education: 'SMK',
    individual_field_of_study: 'Multimedia',
    individual_work_experiences: [
      {
        position: 'Software Engineer',
        company_name: 'Warung Teknologi',
        is_current_company: true,
        start_month: 8,
        start_year: 2021,
        end_month: 1,
        end_year: 2022,
        description: 'lorem',
      },
      {
        position: 'Software Engineer',
        company_name: 'Telkom',
        is_current_company: false,
        start_month: 2,
        start_year: 2019,
        end_month: 1,
        end_year: 2020,
        description: '',
      },
    ],
    registration_state: 'Draft', // 'Draft/Submitted/Approved/Rejected'
  },
  meta: null,
}

export const REGISTER_POST_DOCUMENT_FILE_SUCCESS_RESPONSE = {
  data: {
    id: 'kqKQZ5op0D',
    user_email: 'sigit@wartek.belajar.id',
    file_size: 23084,
    file_name: 'sample-pdf-1-mb.pdf',
    file_type: 'document',
    document_type: 'work_sample',
    url: '',
    file_extension: 'pdf',
  },
  meta: {},
}

export const REGISTER_POST_DOCUMENT_URL_SUCCESS_RESPONSE = {
  data: {
    id: 'kqKQZ5op0D',
    user_email: 'sigit@wartek.belajar.id',
    file_size: 0,
    file_name: '',
    file_type: 'url',
    document_type: 'work_sample',
    url: 'http://google.com',
    file_extension: '',
  },
  meta: {},
}
