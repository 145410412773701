import { Separator } from '@wartek-id/separator'
import { Text } from '@wartek-id/text'

import { Modal } from 'components/Modal'

import type { AuthError } from 'types/authError'

interface ErrorModalProps {
  data: AuthError
  isOpen: boolean
  onClose: () => void
  onRelogin: (provider: string) => void
}

export const ErrorModal = ({
  data,
  isOpen,
  onClose,
  onRelogin,
}: ErrorModalProps) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    title="Akun Belum Terdaftar"
    className="w-full md:w-[500px]"
  >
    <div>
      <div className="mb-8">
        <Text color="subdued" variant="body">
          Berikut yang bisa Anda lakukan:
        </Text>
      </div>

      <div className="flex space-x-4">
        <div className="flex justify-between">
          <div className="border border-gray-30 rounded-full w-8 h-8 flex justify-center items-center">
            1
          </div>
        </div>
        <div className="flex-1">
          <div className="mb-3">
            Pastikan Anda masuk dengan Akun Google, atau dengan alamat email
            yang Anda gunakan untuk masuk ke platform Merdeka Mengajar.
          </div>
          <div>
            <a
              className="text-primary font-semibold cursor-pointer"
              onClick={() => onRelogin(data?.provider)}
            >
              Coba masuk lagi
            </a>
          </div>
        </div>
      </div>
      <Separator className="my-4" />
      <div className="flex space-x-4">
        <div className="flex justify-between">
          <div className="border border-gray-30 rounded-full w-8 h-8 flex justify-center items-center">
            2
          </div>
        </div>
        <div className="flex-1">
          <div className="mb-3">
            Jika Anda adalah Kontributor Perangkat Ajar, pastikan Anda
            menggunakan alamat email yang sudah didaftarkan ke Ruang Kolaborasi.
          </div>
          <div>
            <a
              className="text-primary font-semibold cursor-pointer"
              href="https://ruangkolaborasi.zendesk.com/hc/en-us/articles/5011014888473-Alur-pendaftaran"
              target="_blank"
              rel="noreferrer"
            >
              Lihat cara daftar
            </a>
          </div>
        </div>
      </div>
      <Separator className="my-4" />
      <div className="flex space-x-4">
        <div className="flex justify-between">
          <div className="border border-gray-30 rounded-full w-8 h-8 flex justify-center items-center">
            3
          </div>
        </div>
        <div className="flex-1">
          <div className="mb-3">
            Jika Anda yakin email Anda sudah benar namun tetap tidak bisa masuk,
            mohon hubungi bantuan.
          </div>
          <div>
            <a
              className="text-primary font-semibold cursor-pointer"
              href="https://ruangkolaborasi.zendesk.com/hc/en-us"
            >
              Hubungi Bantuan
            </a>
          </div>
        </div>
      </div>
    </div>
  </Modal>
)
