export const UNPROTECTED_ROUTES = [
  '/healthz',
  '/login',
  '/logout',
  '/error',
  '/404',
  '/',
  '/register',
  '/terms-conditions',
  '/privacy-policy',
]
