/* eslint-disable security/detect-object-injection */
import React from 'react'
import Link from 'next/link'
import Image from 'next/image'

import {
  Dialog,
  DialogContextType,
  DialogOverlay,
  DialogContent,
  DialogTitle,
} from '@wartek-id/dialog'
import { Button } from '@wartek-id/button'
import { Separator } from '@wartek-id/separator'
import { Text } from '@wartek-id/text'
import { Icon } from '@wartek-id/icon'
import { useToast } from '@wartek-id/toast'

import { useGlobalContext } from 'pages/_app'
import { capitalize } from 'utils/text'
import { useAPIPostDocument } from 'api/document'

interface UploadLogoDialogProps extends DialogContextType {}

const uploadDocumentParams = {
  document_type: 'institution_logo',
  file_type: 'document',
}
const UploadLogoDialog = ({
  onClose,
  isOpen,
  ...props
}: UploadLogoDialogProps) => {
  const toast = useToast()
  const { userData, refetchUserData } = useGlobalContext()
  const [error, setError] = React.useState<string>('')
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [logoFile, setLogoFile] = React.useState<any>(null)
  const [logoSrc, setLogoSrc] = React.useState<any>(null)
  const inputFile = React.useRef(null)
  const mutatePostDocument = useAPIPostDocument()
  const personData = {
    Nama: capitalize(userData?.person_name),
    Posisi: userData?.occupation,
    Email: userData?.email,
    'No. HP': userData?.phone,
  }

  const onUnggahLogoClicked = () => {
    inputFile.current.click()
  }

  const onFilePicked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0]
    if (file.size > 1 * Math.pow(1024, 2)) {
      setError('Ukuran file Maks. 1MB')
    } else {
      setError('')
      setLogoFile(e?.target?.files?.[0])
    }
  }

  const save = async () => {
    setIsLoading(true)
    const blob = logoFile.slice(0, logoFile.size, logoFile.type)
    const file = new File([blob], logoFile.name.replaceAll(' ', '_'), {
      type: logoFile.type,
    })

    try {
      await mutatePostDocument({
        payload: {
          file,
          ...uploadDocumentParams,
        },
      })
      refetchUserData()
      toast({
        message: 'Berhasil memperbarui profil',
      })
      onClose()
    } catch (err) {
      console.error(err)
      toast({
        message: 'Terjadi masalah saat upload logo',
      })
    }
    setIsLoading(false)
  }

  React.useEffect(() => {
    if (logoFile) {
      const reader = new FileReader()
      reader.readAsDataURL(logoFile)
      reader.onloadend = () => {
        setLogoSrc(reader.result)
      }
    }
  }, [logoFile])

  React.useEffect(() => {
    if (isOpen) {
      setLogoFile(null)
      const parsedUrl = userData?.institution_logo?.replace(
        'cloud.google',
        'googleapis'
      )
      setLogoSrc(parsedUrl || null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])
  return (
    <Dialog {...props} {...{ isOpen, onClose }} size="xl">
      <DialogOverlay closeOnOverlayClick={false} />
      <DialogContent className="!p-0">
        <DialogTitle className="p-4 !bg-default rounded-t-md">
          <Text variant="heading-md" className="!font-semibold">
            Edit Profil Institusi
          </Text>
        </DialogTitle>
        <div className="p-4 border border-b">
          <input
            type="file"
            ref={inputFile}
            className="hidden"
            accept="image/png,image/jpg,image/jpeg"
            onChange={onFilePicked}
          />
          <div className="mb-4 flex flex-row gap-4">
            <div style={{ flex: '0 0 80px' }}>
              {logoSrc ? (
                <div className="w-20 h-20 rounded-full border border-disabled">
                  <Image
                    src={logoSrc}
                    width={80}
                    height={80}
                    alt="Logo Institusi"
                    className="rounded-full"
                  />
                </div>
              ) : (
                <div className="bg-surface-subdued rounded-full w-20 h-20 border border-disabled flex justify-center items-center">
                  <Icon color="disabled">image</Icon>
                </div>
              )}
            </div>
            <div className="flex-1 flex flex-col justify-center gap-2">
              <Text variant="body-sm" color={error ? 'critical' : 'subdued'}>
                {error || 'Maks. 1MB; format JPG, JPEG, atau PNG'}
              </Text>
              <div>
                <Button color="white" onClick={onUnggahLogoClicked}>
                  {logoFile ? 'Ganti' : 'Unggah'} Logo
                </Button>
              </div>
            </div>
          </div>
          <Text variant="body-lg">{userData?.institution_name || '-'}</Text>
          <Text color="subdued" variant="body">
            {capitalize(userData?.contributor_type || '-')} &bull;{' '}
            {userData?.institution_category}
          </Text>
          <Separator className="mt-2 mb-4" />
          <Text className="!font-semibold" variant="heading-sm">
            Data Narahubung
          </Text>
          <Text variant="body" className="mb-4">
            Untuk mengganti narahubung institusi, silakan{' '}
            <Link href="https://ruangkolaborasi.zendesk.com/hc/en-us" passHref>
              <a target="_blank" className="inline-block">
                <Text>
                  <u>hubungi kami</u>
                </Text>
              </a>
            </Link>
            .
          </Text>
          <div>
            {Object.keys(personData).map((key) => (
              <dl key={key} className="grid grid-cols-4 mb-4">
                <dd className="text-subdued col-span-1">{key}</dd>
                <dt className="col-span-3">{personData[key]}</dt>
              </dl>
            ))}
          </div>
        </div>
        <div className="p-4 flex justify-end gap-2">
          <Button color="white" onClick={() => onClose()} disabled={isLoading}>
            Batal
          </Button>
          <Button
            color="black"
            disabled={!logoFile || isLoading}
            isLoading={isLoading}
            onClick={save}
          >
            Simpan
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default UploadLogoDialog
