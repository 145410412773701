import { useQuery, UseQueryOptions } from 'react-query'
import axios from 'axios'

import { api } from 'configs/api'
import type {
  TToggleKeyName,
  TToggleKeyValuePair,
} from 'configs/toggle-feature'

import type { AxiosResponse } from 'axios'
import type { APIResponse } from 'types/api'
import type { Toggle } from 'types/entities'

const basePath = '/aum/toggle/v1/toggles'
const basePathCore = '/cores/v1alpha1/toggles'

export enum ToggleKey {
  ToolkitsFeature = 'cms_enable_feature_toolkits',
  ForcedMaintenanceMode = 'cms-enable-feature-forced-maintenance-mode',
  ImgTypeAttachmentAnnouncement = 'type-img-attachment-announcement',
}

export function useAPIGetToggleByKeyAum(
  key: ToggleKey,
  queryOpts?: UseQueryOptions<AxiosResponse<APIResponse<Toggle>>>
) {
  return useQuery<AxiosResponse<APIResponse<Toggle>>>(
    ['get-toggle', key],
    () => api().get(`${basePath}/${key}`),
    queryOpts
  )
}

export function useAPIGetToggleByKey(
  key: ToggleKey,
  queryOpts?: UseQueryOptions<AxiosResponse<APIResponse<Toggle>>>
) {
  return useQuery<AxiosResponse<APIResponse<Toggle>>>(
    ['get-toggle', key],
    () => api().get(`${basePathCore}/${key}`),
    queryOpts
  )
}

export function useAPIGetAllToggle(
  queryOpts?: UseQueryOptions<AxiosResponse<TToggleKeyValuePair>>
) {
  return useQuery<AxiosResponse<TToggleKeyValuePair>>(
    ['get-toggle-all'],
    () => axios.get(`/api/toggles`),
    queryOpts
  )
}

export function useAPIgetToggle(
  key: TToggleKeyName,
  queryOpts?: UseQueryOptions<AxiosResponse<TToggleKeyValuePair>>
) {
  return useQuery<AxiosResponse<TToggleKeyValuePair>>(
    ['get-toggle', key],
    () => axios.get(`/api/toggles/${key}`),
    queryOpts
  )
}
