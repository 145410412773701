import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { MaintenanceState } from 'components/Maintenance'
import { useAPIGetToggleByKeyAum, ToggleKey } from 'api/toggles/useAPIToggle'

const isProduction = process.env.NODE_ENV === 'production'
const isStaging = process.env.NODE_ENV === 'test'

export const useMaintenanceMode = () => {
  const router = useRouter()
  const {
    query: {
      forcedMaintenanceEnabled,
      forcedMaintenanceCurrentHour,
      forcedMaintenanceStartHour,
      forcedMaintenanceEndHour,
    },
  } = router // for testing purpose
  const [isMaintenanceModeEnabled, setMaintenanceMode] =
    useState<boolean>(false)
  const [isMaintenanceBannerEnabled, setMaintenanceBanner] =
    useState<boolean>(false)

  const MAINTENANCE_MODE_ENABLED =
    forcedMaintenanceEnabled === 'true' ||
    process.env.NEXT_PUBLIC_MAINTENANCE_MODE_ENABLED === 'true'
  const MAINTENANCE_START_HOUR =
    parseInt(forcedMaintenanceStartHour as string) ||
    parseInt(process.env.NEXT_PUBLIC_MAINTENANCE_START_HOUR)
  const MAINTENANCE_END_HOUR =
    parseInt(forcedMaintenanceEndHour as string) ||
    parseInt(process.env.NEXT_PUBLIC_MAINTENANCE_END_HOUR)

  const date = new Date()
  const currentHour =
    parseInt(forcedMaintenanceCurrentHour as string) || date.getUTCHours() + 7

  useEffect(() => {
    // enable maintenance banner -1 hour before
    if (
      MAINTENANCE_MODE_ENABLED &&
      currentHour === MAINTENANCE_START_HOUR - 1
    ) {
      setMaintenanceBanner(true)
    }

    // enable maintenance mode page
    if (
      MAINTENANCE_MODE_ENABLED &&
      currentHour >= MAINTENANCE_START_HOUR &&
      currentHour <= MAINTENANCE_END_HOUR
    ) {
      setMaintenanceMode(true)
    }

    return () => {
      setMaintenanceBanner(false)
      setMaintenanceMode(false)
    }
  }, [
    MAINTENANCE_END_HOUR,
    MAINTENANCE_MODE_ENABLED,
    MAINTENANCE_START_HOUR,
    currentHour,
  ])

  return {
    isMaintenanceModeEnabled,
    isMaintenanceBannerEnabled,
    maintenanceStartHour: MAINTENANCE_START_HOUR,
    maintenanceEndHour: MAINTENANCE_END_HOUR,
  }
}

export const MaintenanceProvider = ({ children }) => {
  const { isMaintenanceModeEnabled } = useMaintenanceMode()

  const [isForcedMaintenanceToggleEnabled, setForcedMaintenanceToggle] =
    useState<boolean>(false)

  const { data: toggleResponse, status } = useAPIGetToggleByKeyAum(
    ToggleKey.ForcedMaintenanceMode,
    {
      enabled: isProduction || isStaging,
    }
  )

  useEffect(() => {
    if (
      (isProduction || isStaging) &&
      status === 'success' &&
      !!toggleResponse?.data.data.value
    ) {
      setForcedMaintenanceToggle(true)
    }

    return () => {
      setForcedMaintenanceToggle(false)
    }
  }, [toggleResponse, status, setForcedMaintenanceToggle])

  if (isForcedMaintenanceToggleEnabled || isMaintenanceModeEnabled) {
    return <MaintenanceState isForced={isForcedMaintenanceToggleEnabled} />
  }
  return children
}
