import { UseQueryOptions } from 'react-query'

import { useQueryApi, useMutationApi } from 'utils/api'

import type { APIResponse } from 'types/api'
import type { CurrentUserData } from 'types/users'

const basePath = `/curriculums/users`

interface useAPIGetCurrentUserDataParams {
  opts?: UseQueryOptions<APIResponse<CurrentUserData>>
  queryKey?: any
}

interface InstitutionEmails {
  emails: string[]
}
export function useAPIGetCurrentUserData({
  opts,
  queryKey,
}: useAPIGetCurrentUserDataParams) {
  return useQueryApi<APIResponse<CurrentUserData>>(`${basePath}/me`, {
    queryConfig: {
      useErrorBoundary: false,
      ...opts,
    },
    queryKey: ['get-current-user-data', queryKey],
  })
}

export function useAPIGetInstitutionEmails() {
  return useQueryApi<APIResponse>(`${basePath}/institution_members`, {
    queryConfig: {
      useErrorBoundary: false,
    },
    queryKey: ['get-institution-members'],
  })
}

export function useAPIAdminGetInstitutionEmails({ email }: { email: string }) {
  return useQueryApi<APIResponse>(`${basePath}/institution_members/${email}`, {
    queryConfig: {
      useErrorBoundary: false,
      staleTime: 600000, // will refetch after 10 minutes
    },
    queryKey: ['get-institution-members-admin', email],
  })
}

export function useAPIPostInstitutionEmails(queryClient) {
  return useMutationApi<APIResponse, Error, InstitutionEmails>(
    `${basePath}/institution_members`,
    {
      axiosConfig: { method: 'POST' },
      mutationConfig: {
        onSuccess: () =>
          queryClient.invalidateQueries('get-institution-members'),
      },
    }
  )
}

export function useAPIDeleteInstitutionEmail(queryClient) {
  return useMutationApi<APIResponse, Error, { id: string }>(
    `${basePath}/institution_members`,
    {
      axiosConfig: { method: 'DELETE' },
      mutationConfig: {
        onSuccess: () =>
          queryClient.invalidateQueries('get-institution-members'),
      },
    }
  )
}

export function useAPIPatchPersonDirectory({ email }) {
  return useMutationApi<APIResponse, Error>(
    `${basePath}/person-directories/${email}/groups/contributor`,
    {
      axiosConfig: {
        method: 'PATCH',
        validateStatus: function (status) {
          return [204, 409, 200].includes(status)
        },
      },
    }
  )
}
