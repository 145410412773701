import React, { useState, ReactNode } from 'react'
import Link from 'next/link'
import { ChevronDown } from 'react-feather'
import clsx from 'clsx'
import { useRouter } from 'next/router'

import { Text } from '@wartek-id/text'

import styles from './SidebarLink.module.css'
import type { SidebarLinkProps } from 'components/Layout/SidebarDynamic'

export const SidebarLink = ({
  href,
  children,
  icon: Icon = null,
  subdued = false,
  active = false,
  className = null,
}) => (
  <Link href={href} passHref>
    <a
      className={clsx(
        styles.SidebarLink,
        active && styles.SidebarLinkActive,
        className
      )}
    >
      <div className="flex justify-center items-center">
        {Icon && <Icon className="mr-[10px] w-6" />}
      </div>
      <Text
        variant={active ? 'action' : 'body'}
        color={subdued ? 'subdued' : 'default'}
        className="hover:text-default"
      >
        {children}
      </Text>
    </a>
  </Link>
)

export const SidebarLinkGroup = ({
  defaultOpen,
  title,
  children,
  childrenData,
  icon: Icon,
}: {
  defaultOpen?: boolean
  title: string
  children: ReactNode
  childrenData: SidebarLinkProps[]
  icon?: any
}) => {
  const sidebarLinkHrefs = childrenData?.map((data) => data?.target)
  const router = useRouter()
  const childIsActive = sidebarLinkHrefs?.includes(router.pathname)
  const [isOpen, setIsOpen] = useState<boolean>(!!defaultOpen)

  React.useEffect(() => {
    setIsOpen(childIsActive)
  }, [childIsActive, setIsOpen])

  return (
    <div>
      <button onClick={() => setIsOpen(!isOpen)} className={styles.SidebarLink}>
        {Icon && <Icon className="mr-3 w-6" />}
        <Text
          variant={childIsActive ? 'action' : 'body'}
          className="flex-1 text-left"
        >
          {title}
        </Text>
        <ChevronDown
          className={clsx(
            'transition-transform duration-300',
            isOpen && 'transform -rotate-180'
          )}
        />
      </button>
      {isOpen && <div className="flex flex-col space-y-1">{children}</div>}
    </div>
  )
}
