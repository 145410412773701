import { CurrentUserData } from 'types/users'

export function formatUserDataRequest(
  userData: CurrentUserData,
  requestFields
) {
  return {
    contributor_type: userData.contributor_type,
    institution_name: userData.institution_name,
    institution_category: userData.institution_category,
    institution_profile_url: userData.institution_name,
    occupation: userData.occupation,
    person_name: userData.person_name,
    individual_education: userData.individual_education,
    individual_field_of_study: userData.individual_field_of_study,
    registration_state: userData.registration_state,
    phone: userData.phone,
    ...requestFields,
  }
}
