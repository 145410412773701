import { NextPage } from 'next'
import { SessionProvider } from 'next-auth/react'
import { AppProps } from 'next/app'
import Router from 'next/router'
import NProgress from 'nprogress'
import React, { Dispatch, SetStateAction } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

import { createContext } from '@wartek-id/react-utils'
import { ToastProvider } from '@wartek-id/toast'

// import ToggleFetcher from 'components/ToggleFetcher'
import GlobalDialog from 'components/GlobalDialog'
import type { GlobalDialogContentTypes } from 'components/GlobalDialog/types'
import UserDataFetcher from 'components/UserDataFetcher'
import { AuthGuard } from 'utils/hooks/useAuth'
import { MaintenanceProvider } from 'utils/hooks/useMaintenanceMode'
import { OnlineStatusProvider } from 'utils/hooks/useOnlineStatus'

import 'styles/global.css'

import type { CurrentUserData } from 'types/users'

export interface GlobalContextProps {
  globalDialogContentType: GlobalDialogContentTypes
  globalDialogProps: { [x: string]: any }
  userData: Partial<CurrentUserData>
  refetchUserData: () => void
  setGlobalDialogContentType: Dispatch<SetStateAction<any>>
  setGlobalDialogProps: Dispatch<SetStateAction<{ [x: string]: any }>>
  setShowGlobalDialog: Dispatch<SetStateAction<boolean>>
  setUserData: Dispatch<SetStateAction<Partial<CurrentUserData>>>
  setRefetchUserData: Dispatch<SetStateAction<() => void>>
}

export const [GlobalContextProvider, useGlobalContext] =
  createContext<GlobalContextProps>({
    name: 'GlobalContext',
    strict: false,
  })

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

Router.events.on('routeChangeStart', () => {
  NProgress.start()
})
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  const mockServer = require('configs/mocks/server').default
  mockServer({ environment: process.env.NODE_ENV })
}

export type ComponentAuth = {
  name: string
  permissions: string[]
}

export type NextApplicationPage<P = any, IP = P> = NextPage<P, IP> & {
  auth?: ComponentAuth
}

const useBrowserLayoutEffect = process.browser
  ? React.useLayoutEffect
  : () => {}

function App(props: AppProps) {
  const {
    Component,
    pageProps,
    router,
  }: { Component: NextApplicationPage; pageProps: any; router: any } = props
  const [currentToken, setCurrentToken] = React.useState<'mobile' | 'desktop'>()
  const [userData, setUserData] = React.useState<Partial<CurrentUserData>>(null)
  const [globalDialogContentType, setGlobalDialogContentType] =
    React.useState<any>()
  const [globalDialogProps, setGlobalDialogProps] = React.useState<any>()
  const [showGlobalDialog, setShowGlobalDialog] = React.useState<boolean>(false)
  const [refetchUserData, setRefetchUserData] = React.useState<() => void>(null)

  useBrowserLayoutEffect(() => {
    const root = document.querySelector(':root')
    if (
      router?.pathname === '/' &&
      window.innerWidth <= 768 &&
      (currentToken === 'desktop' || !currentToken)
    ) {
      setCurrentToken('mobile')
      root?.classList?.remove?.('desktop')
      root?.classList?.add?.('mobile')
    } else if (currentToken === 'mobile' || !currentToken) {
      setCurrentToken('desktop')
      root?.classList?.remove?.('mobile')
      root?.classList?.add?.('desktop')
    }
  }, [router.pathname])

  return (
    <OnlineStatusProvider>
      <ToastProvider autoDismiss placement="bottom-center">
        <SessionProvider session={pageProps.session}>
          <QueryClientProvider client={queryClient}>
            <GlobalContextProvider
              value={{
                globalDialogContentType,
                globalDialogProps,
                userData,
                setGlobalDialogContentType,
                setGlobalDialogProps,
                setShowGlobalDialog,
                setUserData,
                refetchUserData,
                setRefetchUserData,
              }}
            >
              {/* <ToggleFetcher> */}
              <MaintenanceProvider>
                <UserDataFetcher />
                <AuthGuard {...pageProps}>
                  <Component {...pageProps} key={router.route} />
                </AuthGuard>
              </MaintenanceProvider>
              {/* </ToggleFetcher> */}
              <GlobalDialog
                isOpen={showGlobalDialog}
                onClose={() => setShowGlobalDialog(false)}
              />
            </GlobalContextProvider>
          </QueryClientProvider>
        </SessionProvider>
      </ToastProvider>
    </OnlineStatusProvider>
  )
}

export default App
