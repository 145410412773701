import Head from 'next/head'
import Image from 'next/image'
import xss from 'xss'

import { Text } from '@wartek-id/text'

import { NotificationBanner } from 'components/NotificationBanner'
import { useMaintenanceMode } from 'utils/hooks/useMaintenanceMode'

interface MaintenanceStateProps {
  isForced?: boolean
}

export const MaintenanceState = ({ isForced }: MaintenanceStateProps) => {
  const { maintenanceEndHour } = useMaintenanceMode()

  return (
    <>
      <div className="flex items-center justify-center w-full h-screen">
        <Head>
          <title>Ruang Kolaborasi Merdeka Mengajar - Dalam Perbaikan</title>
        </Head>

        <div className="flex flex-col justify-center">
          <div className="flex justify-center mb-8">
            <Image
              src="/logo.svg"
              alt="Ruang Kolaborasi Merdeka Mengajar Logo"
              width={300}
              height={70}
            />
          </div>
          <div className="text-center">
            <Text className="mb-2" color="default" variant="display-sm">
              {isForced
                ? 'Sedang ada perbaikan sistem'
                : 'Kami sedang melakukan pemeliharaan'}
            </Text>

            <Text
              color="default"
              variant="body"
              dangerouslySetInnerHTML={{
                __html: xss(
                  isForced
                    ? 'Perbaikan membutuhkan waktu maks. 24 jam.  Mohon coba kembali beberapa saat lagi.'
                    : `Ruang Kolaborasi akan bisa Anda akses kembali pukul <strong>${maintenanceEndHour}:00 WIB</strong>.`
                ),
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export const MaintenanceNotificationBanner = () => {
  const {
    isMaintenanceBannerEnabled,
    maintenanceStartHour,
    maintenanceEndHour,
  } = useMaintenanceMode()

  return (
    <>
      {isMaintenanceBannerEnabled && (
        <NotificationBanner variant="warning">
          <Text variant="body-sm">
            <span>
              INFO: Sistem akan melakukan pemeliharaan rutin setiap hari pukul
            </span>
            &nbsp;
            <strong>
              {maintenanceStartHour}:00-{maintenanceEndHour}:00 WIB
            </strong>
            .
          </Text>
        </NotificationBanner>
      )}
    </>
  )
}
