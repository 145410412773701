import React from 'react'
import { useSession } from 'next-auth/react'

import { Avatar } from '@wartek-id/avatar'
import { Button } from '@wartek-id/button'
import { Icon } from '@wartek-id/icon'
import { Popover, PopoverContent, PopoverTrigger } from '@wartek-id/popover'
import { Text } from '@wartek-id/text'

import styles from './UserProfilePopover.module.css'
import { useGlobalContext } from 'pages/_app'
import { useAuthGuard } from 'utils/hooks/useAuth'
import { useUserStore } from 'stores/user'

export default function UserProfilePopover() {
  const { data: session } = useSession()
  const { unsetSession } = useAuthGuard()
  const { getUserGroupNames } = useUserStore()
  const { userData, setGlobalDialogContentType, setShowGlobalDialog } =
    useGlobalContext()

  const [isOpen, setOpen] = React.useState<boolean>(false)

  const avatarSrc = React.useMemo(
    () => session?.user?.image || session?.user?.user?.picture,
    [session]
  )
  const name = React.useMemo(
    () => session?.user?.user?.name || session?.user?.name,
    [session]
  )
  const email = React.useMemo(
    () => session?.user?.user?.email || session?.user?.email,
    [session]
  )

  const editProfileText =
    userData?.contributor_type == 'institusi' ? 'Institusi' : ''

  function openEditProfileModal() {
    userData?.contributor_type == 'institusi'
      ? setGlobalDialogContentType('EditInstituteProfileDialog')
      : setGlobalDialogContentType('EditIndividuProfileDialog')
    setShowGlobalDialog(true)
  }

  return (
    <Popover
      placement="bottom-start"
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      <PopoverTrigger color="white" size="md" variant="solid">
        <div className="flex flex-row justify-center items-center">
          <Avatar
            src={avatarSrc || ''}
            alt={name}
            name={name}
            className="cursor-pointer"
            fallbackColor="blue"
          />
          <Icon as="i" className="ml-1">
            {isOpen ? 'expand_less' : 'expand_more'}
          </Icon>
        </div>
      </PopoverTrigger>
      <PopoverContent
        style={{
          marginTop: 35,
          maxWidth: 450,
        }}
      >
        <div className="p-4">
          <Text as="h1" variant="heading-sm">
            {name}
          </Text>

          <Text color="subdued" variant="body-sm">
            {email}
          </Text>

          {session?.user?.user?.groups?.[0]?.name && (
            <Text
              color="default"
              variant="body-sm"
              className={styles.UserProfileGroupNames}
            >
              {getUserGroupNames()}
            </Text>
          )}
        </div>

        <hr className="mb-2" />
        <Button
          color="blue"
          className={styles.userProfileMenu}
          fullWidth
          onClick={openEditProfileModal}
          size="md"
          variant="ghost"
        >
          <Icon
            as="i"
            color="default"
            fontSize="small"
            className="mr-2 text-black"
          >
            edit
          </Icon>
          Edit Profil {editProfileText}
        </Button>
        <Button
          color="blue"
          className={styles.userProfileMenu}
          fullWidth
          onClick={() => unsetSession()}
          size="md"
          variant="ghost"
        >
          <Icon
            as="i"
            color="default"
            fontSize="small"
            className="mr-2 text-black"
          >
            login
          </Icon>
          Keluar
        </Button>
      </PopoverContent>
    </Popover>
  )
}
