import { useState, useEffect } from 'react'

// from https://github.com/tailwindlabs/headlessui/issues/479#issuecomment-894657724
export const useIsMounted = () => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)

    return () => {
      setIsMounted(false)
    }
  }, [])

  return isMounted
}
