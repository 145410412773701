import { useCallback } from 'react'
import { useRouter } from 'next/router'

import { permissions } from 'configs/policies'
import roles from 'configs/roles'
import { useRoles } from 'utils/hooks/useRoles'

export function usePermission() {
  const { userHasOneOfRoles } = useRoles()
  const router = useRouter()

  const basePath = `/${router.pathname.split('/')[1]}`

  const getRolesByBasePath = useCallback(() => {
    // Route Path defined on policy
    if (permissions?.[router.pathname]) {
      return (
        permissions?.[router.pathname]?.roles ?? permissions?.[router.pathname]
      )
    }

    // Base Path defined on policy
    // eslint-disable-next-line security/detect-object-injection
    if (permissions?.[basePath]) {
      // eslint-disable-next-line security/detect-object-injection
      return permissions?.[basePath]?.roles ?? permissions?.[basePath]
    }

    return []
  }, [basePath, router])

  const getRolesByActions = useCallback(
    (actions: string[] = []) => {
      let actionRoles = []

      Object.values(actions).forEach((action) => {
        actionRoles = [
          ...actionRoles,
          // eslint-disable-next-line security/detect-object-injection
          ...(permissions?.[router.pathname] || permissions?.[basePath])
            ?.actions?.[action],
        ]
      })

      return [...new Set(actionRoles)]
    },
    [basePath, router]
  )

  const currentPathPermissions = (actions = []) => {
    if (actions.length) {
      const actionsRoles = getRolesByActions(actions)
      return actionsRoles
    }

    return getRolesByBasePath()
  }

  const hasValidRoles = useCallback(
    () => userHasOneOfRoles(Object.values(roles) as string[]),
    [userHasOneOfRoles]
  )
  const hasModulePermissions = useCallback(
    (permissions) => userHasOneOfRoles(permissions as string[]),
    [userHasOneOfRoles]
  )

  const checkPermissions = useCallback(
    (permissions: string[]): Boolean => {
      return hasModulePermissions(permissions) || permissions.length === 0
    },
    [hasModulePermissions]
  )

  return {
    hasValidRoles,
    checkPermissions,
    currentPathPermissions,
  }
}
