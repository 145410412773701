import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSession } from 'next-auth/react'

import {
  Dialog,
  DialogContent,
  DialogOverlay,
  DialogTitle,
} from '@wartek-id/dialog'
import { Text } from '@wartek-id/text'
import { Avatar } from '@wartek-id/avatar'
import { useToast } from '@wartek-id/toast'
import { Separator } from '@wartek-id/separator'
import { Input } from '@wartek-id/input'
import { Button } from '@wartek-id/button'
import { Select } from '@wartek-id/select'

import { useGlobalContext } from 'pages/_app'
import { CurrentUserData } from 'types/users'
import { OCCUPATION_CATEGORY_OPTIONS } from 'app/Registration/constants'
import { formatUserDataRequest } from 'utils/request'
import { validPhoneNumber } from 'utils/regex'
import { useAPIPatchRegisterPrecreate } from 'api/registration/useAPIRegistration'

const EditProfileIndividuModal = ({ isOpen, onClose }) => {
  const [showOtherOccupationField, setShowOtherOccupationField] =
    useState<boolean>(false)
  const { data: session } = useSession()
  const { userData } = useGlobalContext()

  const savedOccupation = userData?.occupation
  const toast = useToast()
  const { mutateAsync: patchPrecreate, status: requestStatus } =
    useAPIPatchRegisterPrecreate()

  const {
    register,
    watch,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm()
  const occupationValue = watch('occupation_category')

  const avatarSrc = useMemo(
    () => session?.user?.image || session?.user?.user?.picture,
    [session]
  )

  useEffect(() => {
    let defaultValues = {
      occupation_category: '',
      occupation_category_other: '',
      phone: '',
    }
    defaultValues.occupation_category_other =
      OCCUPATION_CATEGORY_OPTIONS.includes(savedOccupation)
        ? ''
        : savedOccupation
    defaultValues.occupation_category = OCCUPATION_CATEGORY_OPTIONS.includes(
      savedOccupation
    )
      ? savedOccupation
      : 'Lainnya'

    defaultValues.phone = userData?.phone

    reset({ ...defaultValues })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.occupation])

  useEffect(() => {
    setShowOtherOccupationField(occupationValue === 'Lainnya')
  }, [occupationValue])

  async function onSubmit() {
    const phone = getValues('phone')
    const occupationCategory = getValues('occupation_category')
    const otherOccupations = getValues('occupation_category_other')
    const requestData = formatUserDataRequest(userData as CurrentUserData, {
      occupation:
        occupationCategory == 'Lainnya' ? otherOccupations : occupationCategory,
      phone,
    })

    try {
      await patchPrecreate(requestData)
      toast({
        message: 'Berhasil memperbarui profil',
      })
      onClose()
    } catch (error) {
      console.error(error)
      toast({
        message: 'Terjadi kesalahan. Mohon coba kembali',
      })
    }
  }
  return (
    <Dialog isOpen={isOpen} onClose={onClose} customSize={620}>
      <DialogOverlay closeOnOverlayClick={false} />

      <DialogContent className="!p-6">
        <DialogTitle className=" !bg-default rounded-t-md -mx-6 -mt-6 px-6 py-6 mb-6">
          <Text variant="heading-md" className="!font-semibold">
            Edit Profil
          </Text>
        </DialogTitle>
        <div className="flex items-center gap-x-4 mb-[18px]">
          <Avatar
            size="xxl"
            src={avatarSrc}
            fallbackColor="blue"
            alt="foto-profil-individu"
          />
          <span>
            <Text className="!text-[22px] !font-semibold mb-1">
              {userData?.person_name}
            </Text>
            <Text className="capitalize !text-sm" color="subdued">
              {userData?.email}
            </Text>
          </span>
        </div>
        <Text className="!text-sm " color="subdued">
          Nama dan foto profil diambil dari data pada Akun Google Anda. Untuk
          mengubahnya, <span className="text-black">lihat caranya</span>{' '}
          <a
            className="underline text-black"
            href="https://ruangkolaborasi.zendesk.com/hc/en-us/articles/5491931146777-Edit-Profil"
            target="_blank"
            rel="noreferrer"
          >
            di sini
          </a>
          .
        </Text>

        <Separator className="my-9" />

        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset>
            <div className="mb-1">
              <Text variant="body">Pekerjaan</Text>
            </div>
            <div className="mb-8">
              <Select
                {...register('occupation_category', {
                  required: 'Wajib diisi',
                })}
                isInvalid={!!errors.occupation_category}
                errorMessage={
                  errors.occupation_category &&
                  errors.occupation_category.message
                }
              >
                <option disabled>Pilih</option>
                {OCCUPATION_CATEGORY_OPTIONS.map((category, index) => (
                  <option
                    key={`occupation-category-option-${index}`}
                    value={category}
                  >
                    {category}
                  </option>
                ))}
              </Select>
            </div>
          </fieldset>

          {(showOtherOccupationField ||
            !OCCUPATION_CATEGORY_OPTIONS.includes(occupationValue)) && (
            <fieldset>
              <legend className="mb-1">
                <Text variant="body">Tulis Pekerjaan</Text>
              </legend>
              <div className="mb-8">
                <Input
                  {...register('occupation_category_other', {
                    required: 'Wajib diisi',
                  })}
                  isInvalid={!!errors.occupation_category_other}
                  errorMessage={
                    errors.occupation_category_other &&
                    errors.occupation_category_other.message
                  }
                />
              </div>
            </fieldset>
          )}
          <fieldset>
            <legend className="mb-1">
              <Text variant="body">No. Hp</Text>
            </legend>
            <div>
              <Input
                helperText="Pastikan nomor ini memiliki akun WhatsApp aktif."
                {...register('phone', {
                  required: 'Wajib diisi',
                  validate: async (value) =>
                    !!validPhoneNumber(value) ||
                    'Pastikan nomor HP sudah benar.',
                })}
                isInvalid={!!errors.phone}
                errorMessage={errors.phone && errors.phone.message}
              />
            </div>
          </fieldset>

          <Separator className="mt-[61px] mb-4" />
          <div className="flex justify-end gap-2">
            <Button color="white" onClick={() => onClose()}>
              Batal
            </Button>
            <Button
              isLoading={requestStatus === 'loading'}
              color="black"
              type="submit"
            >
              Simpan
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default EditProfileIndividuModal
