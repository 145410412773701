import { AnyFactories, AnyModels } from 'miragejs/-types'
import { Server, Registry } from 'miragejs'

import { baseURL } from 'configs/api'
import {
  REGISTER_POST_SUCCESS_RESPONSE,
  REGISTER_PRECREATE_GET_SUCCESS_RESPONSE,
  // REGISTER_POST_DOCUMENT_FILE_SUCCESS_RESPONSE,
  REGISTER_POST_DOCUMENT_URL_SUCCESS_RESPONSE,
} from './data'
import { setResponse } from 'utils/mirage'

export function registerHandlers(
  server: Server<Registry<AnyModels, AnyFactories>>
) {
  server.post(
    `${baseURL}/curriculums/users/precreate`,
    setResponse(() => REGISTER_POST_SUCCESS_RESPONSE)
  )

  server.get(
    `${baseURL}/curriculums/users/precreate`,
    setResponse(() => REGISTER_PRECREATE_GET_SUCCESS_RESPONSE)
  )

  server.patch(
    `${baseURL}/curriculums/users/precreate`,
    setResponse(() => REGISTER_POST_SUCCESS_RESPONSE)
  )

  server.post(
    `${baseURL}/curriculums/users/document`,
    setResponse(() => REGISTER_POST_DOCUMENT_URL_SUCCESS_RESPONSE),
    { timing: 2000 }
  )

  server.delete(
    `${baseURL}/curriculums/users/document/:id`,
    setResponse(() => REGISTER_POST_SUCCESS_RESPONSE)
  )
}
