import { useCallback, useEffect, useState } from 'react'
import { getProviders, signIn } from 'next-auth/react'
import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'

import { Button } from '@wartek-id/button'
import { Spinner } from '@wartek-id/spinner'
import { Text } from '@wartek-id/text'
import { useToast } from '@wartek-id/toast'

import { REGISTRATION_TYPE_STORAGE_KEY } from 'configs/auth'
import { UNPROTECTED_ROUTES } from 'configs/routes'
import { MobileNavInfo } from 'components/MobileNavInfo'
import { ErrorModal } from 'app/Login/components/ErrorModal'
import { ERROR_MESSAGES } from 'app/Login/constants'
import { useAsync } from 'utils/hooks/useAsync'
import { useReferrer } from 'utils/hooks/useReferrer'
import { destroyTokenData, getTokenData } from 'utils/auth'
import { removeCookie } from 'utils/cookie'
import { useAuthErrorStore } from 'stores/authError'

/**
  Types
  */
import type { Provider } from 'next-auth/providers'
import type { AuthError } from 'types/authError'

const FOOTER_LINKS = [
  {
    title: 'Panduan dan Bantuan',
    target: 'https://ruangkolaborasi.zendesk.com/hc/en-us',
  },
  {
    title: 'Kebijakan Privasi',
    target: '/privacy-policy',
  },
  {
    title: 'Syarat & Ketentuan',
    target: '/terms-conditions',
  },
]

// interface LoginProps {
//   referrer: string | null
// }

// export async function getServerSideProps(context) {
//   return {
//     props: {
//       referrer: context?.req?.headers?.referer ?? null,
//     },
//   }
// }

export const LoginFooter = () => {
  return (
    <div className="flex-grow-0 mt-12 mb-4 flex-wrap flex justify-center md:justify-between">
      {FOOTER_LINKS.map((link, index) => (
        <div
          key={`login-footer-link-${index}`}
          className="mx-4 md:mx-0 mb-2 md:mb-0"
        >
          <Link href={link.target} passHref>
            <a target="_blank">
              <Text variant="body-sm" className="!font-bold">
                {link.title}
              </Text>
            </a>
          </Link>
        </div>
      ))}
    </div>
  )
}

const Login = () => {
  const toast = useToast()
  const { setReferrer } = useReferrer()

  const [providers, setProviders] = useState<Provider | null>()
  const [isErrorModalOpen, setErrorModalOpen] = useState<boolean>(false)
  const { status, data, run, reset } = useAsync<any>()
  const [flashAuthError, setFlashAuthError] = useState<AuthError | null>(null)

  const {
    authError: persistedAuthError,
    hasAuthError,
    setAuthError: setPersistedAuthError,
  } = useAuthErrorStore()

  const title = 'Masuk Ruang Kolaborasi Merdeka Mengajar'
  const description =
    'Pusat pengelolaan konten dan pengguna platform Merdeka Mengajar.'

  const fetchProviders = useCallback(() => {
    run(getProviders())
  }, [run])

  const errorModalClosedHandler = () => {
    setErrorModalOpen(false)
    setFlashAuthError(null)
  }

  useEffect(() => {
    if (!data) {
      fetchProviders()
    }
  }, [data, fetchProviders])

  useEffect(() => {
    if (status === 'success' && data) {
      setProviders(data)
    }

    if (status === 'error') {
      setProviders(null)
      reset()
    }
  }, [data, status, reset])

  useEffect(() => {
    if (hasAuthError()) {
      const authError = persistedAuthError
      setFlashAuthError(authError)
      setPersistedAuthError()

      if (authError?.type === 'NotRegistered') {
        setErrorModalOpen(true)
      } else {
        toast({
          message: ERROR_MESSAGES[authError?.type],
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAuthError])

  useEffect(() => {
    // if (referrer) {
    // const url = new URL()
    // const referrerPath = url.pathname.replace(
    //   process.env.NEXT_PUBLIC_BASE_PATH,
    //   ''
    // )
    const referrerPath = process.env.NEXT_PUBLIC_BASE_PATH

    if ([...UNPROTECTED_ROUTES, '/validate-session'].includes(referrerPath)) {
      setReferrer('')
      return
    }

    setReferrer(referrerPath)
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (getTokenData()?.guruToken) {
      destroyTokenData()
    }

    removeCookie(REGISTRATION_TYPE_STORAGE_KEY)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>

      <div className="flex flex-col h-screen w-full justify-center px-2">
        <div className="fixed top-0 left-0 w-full z-50 block sm:hidden">
          <MobileNavInfo />
        </div>
        <div className="flex justify-center">
          <div className="max-w-full h-screen md:h-full flex flex-col justify-between">
            <div className="flex-grow md:flex-0 flex flex-col justify-center">
              <div className="flex-grow-0 bg-white rounded-md p-4 py-14 md:p-16 mt-12 sm:mt-8 md:mt-0">
                <div>
                  <Image
                    src="/logo.svg"
                    alt="Ruang Kolaborasi Merdeka Mengajar Logo"
                    width={600}
                    height={177}
                  />
                </div>
                <div className="mb-9 mx-auto">
                  <Text variant="body-lg">{description}</Text>
                </div>

                <div>
                  {!providers && (
                    <div className="flex justify-center">
                      <Spinner size="lg" />
                    </div>
                  )}
                  {providers &&
                    Object.values(providers).map((provider) => (
                      <div key={provider.name} className="flex justify-center">
                        <Button
                          color="blue"
                          variant="solid"
                          size="sm"
                          className="!py-2 !px-8 !flex !justify-between"
                          onClick={() => signIn(provider.id)}
                        >
                          <Image
                            src="/images/logo-kemdikbud.png"
                            alt="Kemdikbud Logo"
                            width={32}
                            height={32}
                            className="flex-grow-0"
                          />
                          <span className="ml-2 px-6 flex-grow">
                            Masuk dengan {provider.name}
                          </span>
                        </Button>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ErrorModal
        data={flashAuthError}
        isOpen={isErrorModalOpen}
        onClose={errorModalClosedHandler}
        onRelogin={(provider) => signIn(provider)}
      />
    </>
  )
}

export default Login
