import axios from 'axios'
import { UseQueryOptions } from 'react-query'

import { baseURL } from 'configs/api'
import { useQueryApi, useMutationApi } from 'utils/api'
import { getTokenData } from 'utils/auth'

import type { APIResponse } from 'types/api'
import type {
  RegistrationPrecreate,
  RegistrationDocument,
  RegistrationDocumentBody,
  RegistrationPrecreateWorkExperience,
} from 'types/registration'

interface APIPostRegisterDocumentParam {
  payload: any
  onUploadProgress?: (e: ProgressEvent) => void
}

interface WorkExperiencePostResponse {
  id: string
}

const basePath = '/curriculums/users'

export function useAPIGetRegisterPrecreate(
  opts?: UseQueryOptions<APIResponse<RegistrationPrecreate[]>>
) {
  return useQueryApi<APIResponse<any>>(`${basePath}/precreate`, {
    queryConfig: {
      ...opts,
      useErrorBoundary: false,
    },
    queryKey: ['get-register-precreate'],
  })
}

export function useAPIPatchRegisterPrecreateState() {
  return useMutationApi<
    APIResponse<any>,
    Error,
    {
      event_name: 'submit' | 'draft'
    }
  >(`${basePath}/precreate/state`, {
    axiosConfig: {
      method: 'PATCH',
    },
  })
}

export function useAPIPatchRegisterPrecreate() {
  return useMutationApi<APIResponse<any>, Error, RegistrationPrecreate>(
    `${basePath}/precreate`,
    {
      axiosConfig: {
        method: 'PATCH',
      },
    }
  )
}

export function useAPIPostRegisterDocument() {
  return async ({
    payload,
    onUploadProgress = () => {},
  }: APIPostRegisterDocumentParam) => {
    const formDataPayload = new FormData()
    Object.keys(payload).forEach((key) => {
      // eslint-disable-next-line security/detect-object-injection
      formDataPayload.append(key, payload[key])
    })

    return axios.post(`${baseURL}${basePath}/document`, formDataPayload, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getTokenData()?.idToken}`,
      },
      onUploadProgress,
    })
  }
}

export function useAPIDeleteRegisterDocument() {
  return useMutationApi<
    APIResponse<RegistrationDocument>,
    Error,
    RegistrationDocumentBody
  >((id) => `${basePath}/document/${id}`, {
    axiosConfig: {
      method: 'DELETE',
    },
  })
}

export const useAPIPostWorkExperience = (queryClient) => {
  return useMutationApi<
    APIResponse<WorkExperiencePostResponse>,
    Error,
    RegistrationPrecreateWorkExperience
  >(`${basePath}/work_experiences`, {
    axiosConfig: { method: 'POST' },
    mutationConfig: {
      onSuccess: () => queryClient.invalidateQueries('get-register-precreate'),
    },
  })
}

export function useAPIDeleteWorkExperience(queryClient) {
  return useMutationApi<APIResponse<{}>, Error, string>(
    (id) => `${basePath}/work_experiences/${id}`,
    {
      axiosConfig: {
        method: 'DELETE',
      },
      mutationConfig: {
        onSuccess: () =>
          queryClient.invalidateQueries('get-register-precreate'),
      },
    }
  )
}

export function useAPIPatchWorkExperience(queryClient) {
  return useMutationApi<
    APIResponse<RegistrationPrecreateWorkExperience>,
    Error,
    RegistrationPrecreateWorkExperience
  >(`${basePath}/work_experiences`, {
    axiosConfig: { method: 'PATCH' },
    mutationConfig: {
      onSuccess: () => queryClient.invalidateQueries('get-register-precreate'),
    },
  })
}
