export const GET_CURRICULUM_FILTERS_SUCCESSFUL_RESPONSE = {
  pancasila_student_profiles: [
    'Berkebinekaan Global',
    'Mandiri',
    'Gotong Royong',
    'Bernalar Kritis',
    'Kreatif',
    'Beriman, Bertakwa kepada Tuhan yang Maha Esa, dan Berakhlak Mulia',
  ],
  school_levels: ['PAUD', 'SD', 'SMP', 'SMA', 'SMK'],
  slb_school_levels: ['PAUDLB', 'SDLB', 'SMPLB', 'SMALB'],
  classes: [
    'TK A',
    'TK B',
    'Kelas 1',
    'Kelas 2',
    'Kelas 3',
    'Kelas 4',
    'Kelas 5',
    'Kelas 6',
    'Kelas 7',
    'Kelas 8',
    'Kelas 9',
    'Kelas 10',
    'Kelas 11',
    'Kelas 12',
  ],
  subjects: [
    'PPKn',
    'Bahasa Indonesia',
    'Bahasa Inggris',
    'IPA',
    'IPS',
    'Informatika',
    'IPA Kelas 10',
    'IPS Kelas 10',
    'Biologi',
    'Ekonomi',
    'Fisika',
    'Kimia',
    'Sejarah',
    'Sosiologi',
    'Ekonomi',
    'Geografi',
    'Antropologi',
    'Bahasa dan Sastra Indonesia',
    'Bahasa dan Sastra Inggris',
    'Bahasa dan Sastra Korea',
    'Bahasa dan Sastra Arab',
    'Bahasa dan Sastra Jepang',
    'Bahasa dan Sastra Jerman',
    'Bahasa dan Sastra Perancis',
    'Modul projek - Suara Demokrasi',
    'Modul projek - Kewirausahaan',
    'Modul projek - Kearifan Lokal',
    'Modul projek - Bhinneka Tunggal Ika',
    'Modul projek - Berekayasa dan Berteknologi untuk Membangun NKRI',
    'Kepercayaan',
    'PJOK',
  ],
  phases: ['A', 'B', 'C', 'D', 'E', 'F'],
  learning_modelss: [
    'Tatap Muka',
    'PJJ Daring',
    'PJJ Luring',
    'Pembelajaran Campuran',
  ],
  material_types: [
    'Materi Pengayaan',
    'Materi untuk siswa yang kesulitan belajar',
  ],
  subject_domain_lists: {
    'Bahasa Indonesia': [
      'Berbicara dan Mempresentasikan',
      'Membaca dan Memirsa',
      'Menulis',
      'Menyimak',
    ],
    'Bahasa Inggris': [
      'Membaca - Memirsa',
      'Menulis - Mempresentasikan',
      'Menyimak - Berbicara',
    ],
    Biologi: ['Keterampilan proses', 'Pemahaman Biologi'],
    Ekonomi: ['Keterampilan Proses', 'Pemahaman Konsep'],
    Fisika: ['Keterampilan Proses', 'Pemahaman Fisika'],
    IPA: ['Keterampilan proses', 'Pemahaman IPA'],
    Kimia: ['Keterampilan proses', 'Pemahaman Kimia'],
    Matematika: [
      'Aljabar',
      'Aljabar dan Fungsi',
      'Analisis Data dan Peluang',
      'Bilangan',
      'Geometri',
      'Kalkulus',
      'Pengukuran',
    ],
    PPKn: [
      'Bhinneka Tunggal Ika',
      'Negara Kesatuan Republik Indonesia',
      'Pancasila',
      'Undang-Undang Dasar Negara Republik Indonesia Tahun 1945',
    ],
    'Seni Musik': [
      'Mengalami (Experiencing',
      'Merefleksikan (Reflecting)',
      'Berpikir dan Bekerja Secara Artistik (Thinking and Working Artistically)',
      'Menciptakan (Creating)',
      'Berdampak (Impacting) Bagi Diri Sendiri dan Orang Lain',
    ],
    Informatika: [
      'Berpikir Komputasional (BK)',
      'Teknologi Informasi dan Komunikasi (TIK)',
      'Sistem Komputer (SK)',
      'Jaringan Komputer dan Internet (JKI)',
      'Analisis data (AD)',
      'Algoritma dan Pemrograman (AP)',
      'Dampak Sosial Informatika (DSI)',
      'Praktik Lintas Bidang (PLB)',
    ],
  },
  phase_class_lists: {
    A: ['Kelas 1', 'Kelas 2'],
    B: ['Kelas 3', 'Kelas 4'],
    C: ['Kelas 5', 'Kelas 6'],
    D: ['Kelas 7', 'Kelas 8', 'Kelas 9'],
    E: ['Kelas 10'],
    F: ['Kelas 11', 'Kelas 12'],
    PAUD: ['PAUD'],
  },
  phase_subject_lists: {
    A: [
      'Modul projek - Bhinneka Tunggal Ika',
      'Modul projek - Kewirausahaan',
      'Agama Hindu',
      'Agama Kristen',
      'Seni Tari',
      'Modul projek - Berekayasa dan Berteknologi untuk Membangun NKRI',
      'Bahasa Inggris',
      'Kepercayaan',
      'Seni Rupa',
      'Agama Konghucu',
      'Bahasa Indonesia',
      'Matematika',
      'Agama Katolik',
      'Seni Musik',
      'Agama Buddha',
      'Modul projek - Kearifan Lokal',
      'PJOK',
      'PPKn',
    ],
    B: [
      'Informatika',
      'PJOK',
      'IPAS',
      'Matematika',
      'PPKn',
      'Seni Musik',
      'Seni Tari',
      'Bahasa Indonesia',
      'Seni Rupa',
    ],
    C: [
      'PPKn',
      'Modul projek - Bhinneka Tunggal Ika',
      'Seni Musik',
      'Seni Rupa',
      'Bahasa Inggris',
      'Seni Tari',
      'Modul projek - Berekayasa dan Berteknologi untuk Membangun NKRI',
      'Modul projek - Kearifan Lokal',
      'Matematika',
      'Bahasa Indonesia',
      'Modul projek - Kewirausahaan',
      'IPAS',
    ],
    D: [
      'Matematika',
      'Bahasa Indonesia',
      'Seni Tari',
      'IPS',
      'PPKn',
      'Informatika',
      'IPA',
      'Modul projek - Suara Demokrasi',
      'Bahasa Inggris',
    ],
    E: [
      'Modul projek - Bhinneka Tunggal Ika',
      'Bahasa Indonesia',
      'Seni Tari',
      'Agama Konghucu',
      'Bahasa Inggris',
      'PPKn',
      'Matematika',
      'Informatika',
      'Seni Rupa',
      'IPA',
      'Kepercayaan',
      'Seni Musik',
    ],
    F: [
      'PPKn',
      'IPA',
      'Bahasa Indonesia',
      'Seni Tari',
      'Seni Rupa',
      'Bahasa Inggris',
      'Matematika',
      'Seni Musik',
    ],
    PAUD: ['Jati Diri', 'Literasi dan STEAM', 'Bermain Berbasis Buku'],
  },
  lesson_hour_school_level_lists: {
    '30 Menit': ['PAUD'],
    '35 Menit': ['SD/sederajat'],
    '40 Menit': ['SMP/sederajat'],
    '45 Menit': ['SMA/sederajat', 'SMK'],
  },
}
