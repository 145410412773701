import axios from 'axios'

import { baseURL } from 'configs/api'
import { getTokenData } from 'utils/auth'
const basePath = '/curriculums/users'

export interface APIPostLogoInstitusiParams {
  payload: any
}

export function useAPIPostDocument() {
  return async ({ payload }: APIPostLogoInstitusiParams) => {
    const formDataPayload = new FormData()
    Object.keys(payload).forEach((key) => {
      // eslint-disable-next-line security/detect-object-injection
      formDataPayload.append(key, payload[key])
    })

    return axios.post(`${baseURL}${basePath}/document`, formDataPayload, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getTokenData()?.idToken}`,
      },
    })
  }
}
