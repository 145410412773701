import type { FileType } from 'components/FileDropArea/FileDropArea'

export const CONTRIBUTORS_TYPE = [
  {
    name: 'Institusi',
    description:
      'Badan usaha, instansi pemerintah, komunitas, organisasi, sekolah atau satuan pendidikan lainnya.',
  },
  {
    name: 'Individu',
    description:
      'Dosen, editor/penulis buku, kreator konten pendidikan, tutor, atau lainnya (tanpa membawa nama institusi).',
  },
]

// Field: Institution Category
export const INSTITUTION_CATEGORY_OPTIONS = [
  'Badan usaha (PT, CV, koperasi)',
  'Instansi pemerintah / pemda',
  'Komunitas pendidikan',
  'Organisasi guru dan tenaga kependidikan',
  'Organisasi non-pemerintah / nirlaba',
  'Perguruan tinggi',
  'Satuan pendidikan',
]

// Field: Occupation Category
export const OCCUPATION_CATEGORY_OPTIONS = [
  'Dosen',
  'Editor/penulis buku',
  'Kreator konten pendidikan',
  'Tutor',
  'Lainnya',
]

// Field: Education
export const EDUCATION_OPTIONS = ['SMA', 'SMK', 'D1/D2/D3', 'S1', 'S2', 'S3']

// Field: Institution File
export const INSTITUTION_MAX_FILE_SIZE = 3 * Math.pow(1024, 2) // 3 MB

// Field: Work Sample
export const WORK_SAMPLE_MIN_FILE_COUNT = 3
export const WORK_SAMPLE_MAX_FILE_COUNT = 5
export const WORK_SAMPLE_MAX_FILE_SIZE = 5 * Math.pow(1024, 2) // 5 MB
export const WORK_SAMPLE_ACCEPTED_FILE_TYPE = [
  'pdf',
  'png',
  'jpg',
  'jpeg',
] as FileType[]

// Field: Work Experience
export const WORK_EXPERIENCE_MIN_COUNT = 1

// Error Messages
export const REMOVE_DOCUMENT_ERROR_MESSAGE =
  'Terjadi kesalahan saat menghapus dokumen. Silakan coba lagi.'
export const UPLOAD_DOCUMENT_ERROR_MESSAGE =
  'Terjadi kesalahan saat mengunggah dokumen. Silakan coba lagi.'
export const REMOVE_WORK_EXPERIENCE_ERROR_MESSAGE =
  'Terjadi kesalahan saat menghapus pengalaman kerja. Silakan coba lagi.'
export const SUBMIT_WORK_EXPERIENCE_ERROR_MESSAGE =
  'Terjadi kesalahan saat menyimpan pengalaman kerja. Silakan coba lagi.'
