import axios, { AxiosRequestConfig, AxiosInstance } from 'axios'
import { stringify } from 'query-string'

export const baseURL =
  process.env.NEXT_PUBLIC_API_HOST ?? 'https://api.staging.belajar.id'

export const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

export const paramsSerializer = (params?: Record<string, any>): string =>
  stringify(params ?? {}, { arrayFormat: 'none' })

export const api = (opts?: AxiosRequestConfig): AxiosInstance => {
  return axios.create({
    baseURL,
    headers,
    paramsSerializer,
    ...opts,
  })
}
