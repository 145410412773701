import { ComponentType, ReactNode } from 'react'
import clsx from 'clsx'

import BaseSidebar from './SidebarDynamic'
import BaseHeader from './Header'
import ROLES from 'configs/roles'
import { MaintenanceNotificationBanner } from 'components/Maintenance'
import { useMaintenanceMode } from 'utils/hooks/useMaintenanceMode'

import Home from '/public/icon/home.svg'
import ModulAjar from '/public/icon/file-modul-ajar.svg'
import BahanAjar from '/public/icon/file-bahan-ajar.svg'
import ModulProyek from '/public/icon/file-modul-proyek.svg'
import BankSoal from '/public/icon/file-bank-soal.svg'
import PaketSoal from '/public/icon/file-paket-soal.svg'
import Persons from '/public/icon/persons.svg'

interface LayoutProps {
  children: ReactNode
  bgClassName?: string
  className?: string
  sidebar?: ComponentType
  hideSidebar?: boolean
  header?: ComponentType
  sectionProps?: React.HTMLAttributes<HTMLDivElement>
  useNextAuth?: boolean
}

const sidebarLinks = [
  {
    title: 'Beranda',
    target: '/home',
    isIndex: true,
    icon: Home,
  },
  {
    title: 'Modul Ajar',
    target: '/modul-ajar',
    icon: ModulAjar,
  },
  {
    title: 'Bahan Ajar',
    target: '/bahan-ajar',
    icon: BahanAjar,
  },
  {
    title: 'Modul Projek',
    target: '/modul-projek',
    icon: ModulProyek,
  },
  {
    title: 'Daftar Kontributor',
    target: '/contributors',
    icon: Persons,
    permission: [ROLES.TOOLKIT_ADMIN, ROLES.ASSESSMENT_ADMIN, ROLES.ADMIN],
  },
  {
    title: 'Bank Soal',
    target: '/question-bank',
    icon: BankSoal,
    permission: [ROLES.TOOLKIT_ADMIN, ROLES.ASSESSMENT_ADMIN, ROLES.ADMIN],
  },
  {
    title: 'Paket Soal',
    target: '/question-packets',
    icon: PaketSoal,
    permission: [ROLES.TOOLKIT_ADMIN, ROLES.ASSESSMENT_ADMIN, ROLES.ADMIN],
  },
]

export default function Layout({
  children,
  bgClassName,
  className = '',
  sidebar,
  hideSidebar = false,
  header,
  sectionProps = {},
  useNextAuth = false,
}: LayoutProps) {
  const Header = header ?? BaseHeader
  const Sidebar =
    sidebar ??
    (() => <BaseSidebar links={sidebarLinks} title="Perangkat Ajar" />)
  const containerClass = clsx(
    'w-full sm:px-4 md:px-6 lg:px-8 py-4 mt-4',
    className
  )
  const { isMaintenanceBannerEnabled } = useMaintenanceMode()

  return (
    <>
      <MaintenanceNotificationBanner />

      <Header
        className={clsx(isMaintenanceBannerEnabled && '!pt-4 !top-2')}
        useNextAuth={useNextAuth}
      />
      <div className="mt-24 relative">
        <Sidebar />

        <div
          className={clsx(
            'flex sm:ml-0 md:ml-0',
            hideSidebar && 'lg:ml-0',
            !hideSidebar && 'lg:ml-64'
          )}
        >
          <div
            className={clsx(
              'flex flex-col w-full',
              bgClassName,
              !bgClassName && 'bg-white'
            )}
          >
            <section
              className={containerClass}
              style={{
                minHeight: 'calc(100vh - 7rem)',
              }}
              {...sectionProps}
            >
              {children}
            </section>
          </div>
        </div>
      </div>
    </>
  )
}
