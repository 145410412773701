import { useSession } from 'next-auth/react'

import ROLES from 'configs/roles'

const ADMIN_ROLES = [ROLES.ADMIN, ROLES.ASSESSMENT_ADMIN, ROLES.TOOLKIT_ADMIN]

export const useRoles = () => {
  const { data: session } = useSession()
  const currentUserGroups = (session?.user?.user?.groups ?? []).map(
    ({ name }) => name
  )

  const userHasRole = (role: string): boolean =>
    currentUserGroups.includes(role)

  const userHasOneOfRoles = (roles: string[]) => {
    return roles.some((role) => currentUserGroups.includes(role?.toLowerCase()))
  }

  const userIsAdmin = () => userHasOneOfRoles(ADMIN_ROLES)

  const getUserOrganizations = async () => {
    return (session?.user?.user?.groups ?? []).filter(
      ({ groupType }) => groupType === 'GT_PARTNER'
    )
  }

  return {
    userHasRole,
    userHasOneOfRoles,
    userRoles: currentUserGroups,
    userIsAdmin,
    getUserOrganizations,
  }
}
