import React from 'react'

import { DialogContextType } from '@wartek-id/dialog'

import { useGlobalContext } from 'pages/_app'
import { ContentMapping } from './data'

export interface GlobalDialogProps extends DialogContextType {}

const GlobalDialog = (props: GlobalDialogProps) => {
  const { globalDialogContentType, globalDialogProps } = useGlobalContext()
  if (globalDialogContentType) {
    // eslint-disable-next-line security/detect-object-injection
    return React.createElement(ContentMapping[globalDialogContentType], {
      ...props,
      ...globalDialogProps,
    })
  }
  return <div></div>
}

export default GlobalDialog
