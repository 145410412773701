import { AUTH_STORAGE_KEY, REGISTRATION_TYPE_STORAGE_KEY } from 'configs/auth'
import { setCookie } from 'utils/cookie'

import type { TokenData } from 'types/api'

export function persistTokenData(tokenData: TokenData) {
  window.localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(tokenData))
}

export function getTokenData(): TokenData {
  return JSON.parse(window.localStorage.getItem(AUTH_STORAGE_KEY)) ?? {}
}

export function destroyTokenData() {
  window.localStorage.removeItem(AUTH_STORAGE_KEY)
  setCookie(REGISTRATION_TYPE_STORAGE_KEY, '')
}
