import React, { ReactElement } from 'react'
import ReactModal from 'react-modal'
import clsx from 'clsx'
import Image from 'next/image'

import { Button } from '@wartek-id/button'
import { Text } from '@wartek-id/text'

import IconClose from 'public/icon/close.svg'

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: ReactElement
  className?: string
  isOpen: boolean
  onClose?: () => void
  shouldCloseOnOverlayClick?: boolean
  title?: string
  fullHeight?: boolean
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '90vh',
    overflow: 'auto',
    padding: '12px',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 999,
  },
}

export function Modal({
  children,
  className,
  isOpen,
  onClose,
  shouldCloseOnOverlayClick = true,
  title = 'Modal Title',
  fullHeight,
  ...props
}: ModalProps) {
  const closeModal = () => {
    onClose()
  }

  return (
    <div {...props}>
      <ReactModal
        isOpen={isOpen}
        style={customStyles}
        contentLabel={title}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        ariaHideApp={false}
      >
        <div className={className}>
          <div className={clsx(fullHeight && 'h-full', 'p-2')}>
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-10 flex flex-col">
                <Text color="default" variant="heading-lg" className="mb-2">
                  {title}
                </Text>
              </div>
              <div className="col-span-2 text-right">
                <div className="flex justify-end">
                  <Button
                    onClick={closeModal}
                    size="sm"
                    variant="ghost"
                    className="!p-3"
                  >
                    <Image
                      src={IconClose}
                      width={14}
                      height={14}
                      alt="Icon Close"
                    />
                  </Button>
                </div>
              </div>
            </div>

            {children}
          </div>
        </div>
      </ReactModal>
    </div>
  )
}
