import { ReactNode } from 'react'
import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { Text } from '@wartek-id/text'
import { Icon } from '@wartek-id/icon'

import { SidebarLink, SidebarLinkGroup } from './SidebarLink'
import styles from './SidebarDynamic.module.css'
import AccessControl from 'components/AccessControl'

export interface SidebarLinkProps {
  title: string
  icon?: ReactNode
  isIndex?: boolean
  target?: string
  permission?: string[]
  children?: SidebarLinkProps[]
}

export interface SidebarDynamicProps {
  title?: string
  links: SidebarLinkProps[]
}

function PageLinks({ links }: SidebarDynamicProps) {
  const router = useRouter()

  return (
    <div className="flex flex-col space-y-1 w-full">
      {Object.values(links).map((link, index) => {
        const { title, icon, target, isIndex, children, permission } = link
        const basePath = router.pathname.split('/').filter((i) => i)?.[0]
        const active =
          basePath === target.replace('/', '') ||
          (router.pathname === '/' && isIndex)

        if (children) {
          return (
            <AccessControl allowedPermissions={permission} key={index}>
              <SidebarLinkGroup
                title={title}
                icon={icon}
                defaultOpen={true}
                childrenData={children}
              >
                {children.map((child, idx) => (
                  <AccessControl
                    allowedPermissions={child.permission}
                    key={idx}
                  >
                    <SidebarLink
                      href={child.target}
                      icon={child.icon}
                      active={router.pathname === child.target}
                      className={styles.sidebarDynamicChildrenLink}
                    >
                      {child.title}
                    </SidebarLink>
                  </AccessControl>
                ))}
              </SidebarLinkGroup>
            </AccessControl>
          )
        }
        return (
          <AccessControl allowedPermissions={permission} key={index}>
            <SidebarLink
              key={`sidebar-link-${index}`}
              href={target}
              icon={icon}
              active={active}
            >
              {title}
            </SidebarLink>
          </AccessControl>
        )
      })}
    </div>
  )
}

function SidebarContainer(props) {
  return (
    <aside
      className={clsx(
        styles.sidebarDynamicContainer,
        'fixed mt-24 top-0 w-64 h-full bg-default z-10 overflow-y-auto hidden lg:block'
      )}
      {...props}
    />
  )
}

export default function SidebarDynamic({
  title = 'Guru Dashboard',
  links = [],
  ...props
}: SidebarDynamicProps) {
  return (
    <SidebarContainer>
      <div className="flex align-center w-full h-16 px-3 py-8 mb-4">
        <div className="flex align-center px-3 w-full">
          <div className="flex align-center w-full">
            <div className="h-8 w-auto">
              <Text variant="heading-md">{title}</Text>
            </div>
          </div>
        </div>
      </div>
      <nav
        className="flex flex-col relative p-3 overflow justify-between"
        style={{ height: 'calc(100vh - 10rem)' }}
        aria-label="Main navigation"
        {...props}
      >
        <PageLinks links={links} />
        <Link href="https://ruangkolaborasi.zendesk.com/hc/en-us" passHref>
          <a target="_blank">
            <div className="mb-8 flex flex-row gap-2">
              <Icon className="!text-subdued">help</Icon>
              <Text color="subdued">Panduan dan Bantuan</Text>
            </div>
          </a>
        </Link>
      </nav>
      aa
    </SidebarContainer>
  )
}
