// TODO: change response data once BE ready
export const USERS_GET_ALL_SUCCESS_RESPONSE = {
  data: [
    {
      id: '3RDdOLAy64',
      name: 'Oemar Bakri',
      googleEmail: 'oemarbakri@testing.belajar.id',
      groups: [
        {
          id: 'xxxxxx1',
          name: 'contributor',
        },
        {
          id: 'xxxxxx2',
          name: 'admin',
        },
      ],
      createdAt: '2020-01-01T00:00:00Z',
    },
    {
      id: 'bV0Bj28L0Z',
      name: 'Guru Satu',
      googleEmail: 'guru1@testing.belajar.id',
      groups: [
        {
          id: 'xxxxxx1',
          name: 'contributor',
        },
        {
          id: 'xxxxxx2',
          name: 'admin',
        },
        {
          id: 'xxxxxx3',
          name: 'curator',
        },
      ],
      createdAt: '2020-01-01T00:00:00Z',
    },
    {
      id: 'K1Dwx4lm93',
      name: 'Guru Karir Automation',
      googleEmail: 'autoamtion2-karir@testing.belajar.id',
      groups: [
        {
          id: 'xxxxxx1',
          name: 'contributor',
        },
        {
          id: 'xxxxxx2',
          name: 'admin',
        },
      ],
      createdAt: '2020-01-01T00:00:00Z',
    },
    {
      id: 'BP9PWVXNDL',
      name: 'Guru Karir Satu',
      googleEmail: 'gurukarir1@testing.belajar.id ',
      groups: [
        {
          id: 'xxxxxx1',
          name: 'contributor',
        },
        {
          id: 'xxxxxx2',
          name: 'admin',
        },
      ],
      createdAt: '2020-01-01T00:00:00Z',
    },
    {
      id: 'dJ6yQ8Jp68',
      name: 'Guru Karir Satu',
      googleEmail: 'gurukarir1@testing.belajar.id',
      groups: [
        {
          id: 'kjsdldfj',
          name: 'teacher',
        },
        {
          id: 'kjsdldfj',
          name: 'admin',
        },
      ],
      createdAt: '2020-01-01T00:00:00Z',
    },
    {
      id: '3J9R5RV8DY',
      name: 'Guru Karir Dua',
      googleEmail: 'gurukarir2@testing.belajar.id',
      groups: [
        {
          id: 'kjsdldfj',
          name: 'teacher',
        },
        {
          id: 'kjsdldfj',
          name: 'admin',
        },
      ],
      createdAt: '2020-01-01T00:00:00Z',
    },
    {
      id: 'O16e152M6N',
      name: 'Guru Karir Automation',
      googleEmail: 'automation2-karir@testing.belajar.id',
      groups: [
        {
          id: 'kjsdldfj',
          name: 'teacher',
        },
        {
          id: 'kjsdldfj',
          name: 'admin',
        },
      ],
      createdAt: '2020-01-01T00:00:00Z',
    },
    {
      id: 'ovDnPgnz0j',
      name: 'Khusna Julidar',
      googleEmail: 'khusnajulidar07@guru.smk.belajar.id',
      groups: [
        {
          id: 'kjsdldfj',
          name: 'teacher',
        },
        {
          id: 'kjsdldfj',
          name: 'admin',
        },
      ],
      createdAt: '2020-01-01T00:00:00Z',
    },
    {
      id: 'kL6KL3KLDM',
      name: 'Harimas Ramadhan',
      googleEmail: 'harimasramadhan51@guru.sd.belajar.id',
      groups: [
        {
          id: 'kjsdldfj',
          name: 'teacher',
        },
        {
          id: 'kjsdldfj',
          name: 'admin',
        },
      ],
      createdAt: '2020-01-01T00:00:00Z',
    },
    {
      id: 'od0aJgQE6X',
      name: 'Rika Natalia',
      googleEmail: 'rikanatalia521@guru.sd.belajar.id',
      groups: [
        {
          id: 'kjsdldfj',
          name: 'teacher',
        },
        {
          id: 'kjsdldfj',
          name: 'admin',
        },
      ],
      createdAt: '2020-01-01T00:00:00Z',
    },
  ],
  meta: {
    total: 20,
    limit: 10,
    offset: 0,
  },
}
