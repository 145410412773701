import { AnyFactories, AnyModels } from 'miragejs/-types'
import { Server, Registry } from 'miragejs'

import { baseURL } from 'configs/api'
import {
  ANNOUNCEMENTS_GET_ALL_SUCCESS_RESPONSE,
  ANNOUNCEMENTS_POST_CREATE_SUCCESS_RESPONSE,
} from './data'
import { setResponse } from 'utils/mirage'

export function announcementsHandlers(
  server: Server<Registry<AnyModels, AnyFactories>>
) {
  server.get(
    `${baseURL}/teachers/v1alpha1/admin/announcements`,
    setResponse(() => ANNOUNCEMENTS_GET_ALL_SUCCESS_RESPONSE)
  )

  server.post(
    `${baseURL}/teachers/v1alpha1/admin/announcements`,
    setResponse(() => ANNOUNCEMENTS_POST_CREATE_SUCCESS_RESPONSE)
  )
}
