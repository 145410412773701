import React from 'react'
import NextLink from 'next/link'
import NextImage from 'next/image'
import clsx from 'clsx'
import { useSession } from 'next-auth/react'

import UserProfilePopover from './UserProfilePopover'
import { useIsMounted } from 'utils/hooks/useIsMounted'
import { useUserStore } from 'stores/user'

// import MobileNav from './MobileNav'

interface HeaderProps {
  className?: string
  useNextAuth?: boolean
}

export default function Header({
  className = '',
  useNextAuth = false,
}: HeaderProps) {
  const isMounted = useIsMounted()
  const { user } = useUserStore((state) => ({
    user: state.user,
  }))

  const { data } = useSession()
  const nextAuthSession = data?.user?.user
  const session = useNextAuth ? nextAuthSession : user

  const containerClass = clsx(
    'fixed top-0 left-0 border-b border-subdued border-opacity-30 w-full flex justify-between px-6 h-24 items-center space-x-4 bg-white z-[100]',
    className
  )

  return (
    <header className={containerClass}>
      <NextLink href="/" passHref>
        <div className="flex items-center cursor-pointer">
          <NextImage
            src="/logo.svg"
            alt="Ruang Kolaborasi Merdeka Mengajar Logo"
            width={160}
            height={32}
          />
        </div>
      </NextLink>

      {!!session && isMounted && (
        <div className="flex flex-grow-0 space-x-2">
          <UserProfilePopover />
        </div>
      )}
    </header>
  )
}
