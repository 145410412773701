/* eslint-disable security/detect-object-injection */
import React from 'react'
import Link from 'next/link'

import { Button } from '@wartek-id/button'
import {
  Dialog,
  DialogContextType,
  DialogOverlay,
  DialogContent,
} from '@wartek-id/dialog'
import { Text } from '@wartek-id/text'

import { TOOLKIT_TYPE_PATH_MAP } from 'app/Toolkits/data'
import { capitalize } from 'utils/text'

import type { ToolkitType } from 'types/toolkits'

export interface SubmitSuccessDialogProps extends DialogContextType {
  id?: string
  type?: ToolkitType
  isUserActivated?: boolean
  isRevise?: boolean
}

export interface ContentProps extends Pick<DialogContextType, 'onClose'> {
  id?: string
  type?: ToolkitType
}

export const Titles: { [title in ToolkitType]: string } = {
  modul_ajar: 'Modul ajar',
  bahan_ajar: 'Bahan ajar',
  modul_projek: 'Modul projek',
}

const ReviseContent = ({ type = 'modul_ajar', onClose, id }: ContentProps) => {
  const toolkitType = Titles[type]
  return (
    <div className="p-2">
      <Text variant="display-sm" className="mb-3">
        <strong>Terima kasih, hasil revisi akan kami reviu!</strong>
      </Text>
      <Text className="mb-9">
        Proses reviu membutuhkan waktu <strong>7-14 hari.</strong> Jika lulus,{' '}
        {toolkitType.toLowerCase()} akan langsung diterbitkan kembali ke
        platform Merdeka Mengajar. Jika masih perlu direvisi, tim kami akan
        menghubungi Anda kembali.
      </Text>
      <div className="flex justify-end gap-4">
        <Link passHref href={`/${TOOLKIT_TYPE_PATH_MAP[type]}/${id}`}>
          <a>
            <Button
              color="white"
              onClick={() => onClose()}
              className="capitalize"
            >
              Kembali ke Detail {capitalize(toolkitType)}
            </Button>
          </a>
        </Link>
        <Button color="black" onClick={() => onClose()}>
          Tutup
        </Button>
      </div>
    </div>
  )
}

const ReviewContent = ({ type = 'modul_ajar', onClose }: ContentProps) => {
  const toolkitType = Titles[type]
  return (
    <div className="p-2">
      <Text variant="display-sm" className="mb-3">
        <strong>{Titles[type]} akan kami reviu</strong>
      </Text>
      <Text className="mb-9">
        Proses reviu membutuhkan waktu <strong>maks. 14 hari kerja</strong>.
        Jika lulus, {toolkitType.toLowerCase()} akan langsung diterbitkan ke
        platform Merdeka Mengajar. Jika perlu direvisi, tim kami akan
        menghubungi Anda melalui email untuk menyampaikan umpan balik.
      </Text>
      <div className="flex justify-end gap-4">
        <Button color="black" onClick={() => onClose()}>
          Oke
        </Button>
      </div>
    </div>
  )
}

const SubmittedContent = ({
  type = 'modul_ajar',
  onClose,
  id,
}: ContentProps) => {
  const toolkitType = Titles[type]
  return (
    <div className="p-2">
      <Text variant="display-sm" className="mb-3">
        <strong>
          Terima kasih sudah berkontribusi membangun pendidikan di Indonesia!
        </strong>
      </Text>
      {type === 'modul_projek' ? (
        <Text>
          Modul projek Anda sudah diterbitkan dan bisa dilihat di platform
          Merdeka Mengajar. Anda juga bisa memantau performanya melalui halaman
          detail modul.
        </Text>
      ) : (
        <Text className="mb-9">
          {`Dibutuhkan 1–2 menit hingga ${toolkitType.toLowerCase()} diterbitkan dan bisa dilihat di platform Merdeka Mengajar. Setelah terbit, Anda akan bisa memantau performanya melalui halaman detail.`}
        </Text>
      )}
      <div className="flex justify-end gap-4">
        <Link passHref href={`/${TOOLKIT_TYPE_PATH_MAP[type]}/${id}`}>
          <a>
            <Button
              color="white"
              onClick={() => onClose()}
              className="capitalize"
            >
              Lihat Detail {toolkitType}
            </Button>
          </a>
        </Link>
        <Button color="black" onClick={() => onClose()}>
          Tutup
        </Button>
      </div>
    </div>
  )
}
const SubmitSuccessDialog = ({
  id,
  onClose,
  type = 'modul_ajar',
  isUserActivated = false,
  isRevise = false,
  ...props
}: SubmitSuccessDialogProps) => {
  const getContentBasedOnCondition = () => {
    if (isRevise) {
      return <ReviseContent type={type} onClose={onClose} id={id} />
    }
    if (isUserActivated || type === 'modul_projek') {
      return <SubmittedContent type={type} onClose={onClose} id={id} />
    }
    return <ReviewContent type={type} onClose={onClose} />
  }
  return (
    <Dialog onClose={onClose} {...props}>
      <DialogOverlay></DialogOverlay>
      <DialogContent>
        {React.createElement(getContentBasedOnCondition)}
      </DialogContent>
    </Dialog>
  )
}

export default SubmitSuccessDialog
