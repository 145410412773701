import { AnyFactories, AnyModels } from 'miragejs/-types'
import { Server, Registry } from 'miragejs'

import { baseURL } from 'configs/api'
import {
  USER_GROUPS_GET_ALL_SUCCESS_RESPONSE,
  USER_GROUPS_GET_IMPORT_LIST_SUCCESS_RESPONSE,
  USER_GROUPS_GET_IMPORT_DETAIL_SUCCESS_RESPONSE,
  USER_GROUPS_IMPORT_USERS_SUCCESS_RESPONSE,
} from './data'
import { setResponse } from 'utils/mirage'

export function userGroupsHandlers(
  server: Server<Registry<AnyModels, AnyFactories>>
) {
  server.get(
    `${baseURL}/teachers/v1alpha1/groups`,
    setResponse(() => USER_GROUPS_GET_ALL_SUCCESS_RESPONSE)
  )

  server.get(
    `${baseURL}/teachers/v1alpha1/groups/users`,
    setResponse(() => USER_GROUPS_GET_IMPORT_LIST_SUCCESS_RESPONSE)
  )

  server.get(
    `${baseURL}/teachers/v1alpha1/groups/users/detail/:id`,
    setResponse(() => USER_GROUPS_GET_IMPORT_DETAIL_SUCCESS_RESPONSE)
  )

  server.post(
    `${baseURL}/teachers/v1alpha1/groups/users`,
    setResponse(() => USER_GROUPS_IMPORT_USERS_SUCCESS_RESPONSE)
  )

  server.delete(
    `${baseURL}/teachers/v1alpha1/groups/:groupId/users/:userId`,
    setResponse(() => USER_GROUPS_IMPORT_USERS_SUCCESS_RESPONSE)
  )
}
