import { AnyFactories, AnyModels } from 'miragejs/-types'
import { Server, Registry } from 'miragejs'

import { baseURL } from 'configs/api'
import { GET_CURRICULUM_FILTERS_SUCCESSFUL_RESPONSE } from './data'
import { setResponse } from 'utils/mirage'

export function filtersHandlers(
  server: Server<Registry<AnyModels, AnyFactories>>
) {
  server.get(
    `${baseURL}/curriculums/toolkits/filters`,
    setResponse(() => GET_CURRICULUM_FILTERS_SUCCESSFUL_RESPONSE)
  )
}
