import React from 'react'
import clsx from 'clsx'

import { Icon } from '@wartek-id/icon'

import styles from './NotificationBanner.module.css'

interface NotificationBannerProps {
  variant?: 'default' | 'inverse' | 'warning' | 'success' | 'critical'
  children?: React.ReactNode
}

export const NotificationBanner = ({
  variant = 'default',
  children,
}: NotificationBannerProps) => {
  return (
    <div
      className={clsx(
        styles.NotificationBannerContainer,
        variant === 'default' && 'bg-white',
        variant === 'inverse' && 'bg-black',
        variant === 'warning' && 'bg-surface-warning-subdued',
        variant === 'success' && 'bg-surface-success-default',
        variant === 'critical' && 'bg-surface-critical-default'
      )}
    >
      {variant === 'warning' && (
        <Icon as="i" color="default" fontSize="small" className="mr-2">
          info
        </Icon>
      )}
      {children}
    </div>
  )
}
