import { createServer as createServerMirage } from 'miragejs'

import { baseURL } from 'configs/api'
import Models from './models'
import * as apis from './apis'

interface options {
  environment?: 'development' | 'testing'
}

export function createServer({ environment = 'development' }: options = {}) {
  return createServerMirage({
    environment,
    models: Models,
    routes() {
      Object.keys(apis).forEach((key) => {
        // eslint-disable-next-line security/detect-object-injection
        apis[key](this)
      })
      this.passthrough()

      // add external domains to make sure those get passed through
      this.passthrough(`${baseURL}/**`)
      this.passthrough('https://storage.googleapis.com/**')
      this.passthrough('https://uploads.belajar.id/**')
    },
  })
}

export default createServer
