// TODO: change response data once BE ready
export const USER_GROUPS_IMPORT_USERS_SUCCESS_RESPONSE = {
  id: 'WAJ61E67nr',
}

export const USER_GROUPS_GET_IMPORT_LIST_SUCCESS_RESPONSE = {
  data: [
    {
      id: 'oWx0b8DZ1a',
      fileName: 'test10.csv',
      createdBy: 'v5DVnx1D2J',
      createdAt: '2021-11-02 06:16:13.974553 +0000 UTC',
      status: 'IMPORT_DONE',
      totalUserSuccess: '10',
      totalUserFailure: '0',
    },
    {
      id: 'w1BD3v6VQ5',
      fileName: 'test10.csv',
      createdBy: '3J9GxxnZ0l',
      createdAt: '2021-11-02 06:33:12.380492 +0000 UTC',
      status: 'IMPORT_DONE',
      totalUserSuccess: '10',
      totalUserFailure: '0',
    },
    {
      id: 'WAJ61E67nr',
      fileName: 'test10.csv',
      createdBy: '3J9GxxnZ0l',
      createdAt: '2021-11-02 06:35:02.915435 +0000 UTC',
      status: 'IMPORT_DONE',
      totalUserSuccess: '10',
      totalUserFailure: '0',
    },
    {
      id: 'qRv9NW0z3x',
      fileName: 'test10.csv',
      createdBy: '3J9GxxnZ0l',
      createdAt: '2021-11-02 06:36:38.109977 +0000 UTC',
      status: 'IMPORT_DONE',
      totalUserSuccess: '10',
      totalUserFailure: '0',
    },
    {
      id: 'kGn0zM9eNL',
      fileName: 'test10.csv',
      createdBy: '3J9GxxnZ0l',
      createdAt: '2021-11-02 06:36:39.774076 +0000 UTC',
      status: 'IMPORT_DONE',
      totalUserSuccess: '10',
      totalUserFailure: '0',
    },
    {
      id: 'MrkD7j6PW2',
      fileName: 'test10.csv',
      createdBy: '3J9GxxnZ0l',
      createdAt: '2021-11-02 06:50:27.968367 +0000 UTC',
      status: 'IMPORT_DONE',
      totalUserSuccess: '10',
      totalUserFailure: '0',
    },
    {
      id: 'Y7G6ArDWqJ',
      fileName: 'test10fail.csv',
      createdBy: '3J9GxxnZ0l',
      createdAt: '2021-11-02 07:08:40.549485 +0000 UTC',
      status: 'IMPORT_DONE',
      totalUserSuccess: '6',
      totalUserFailure: '4',
    },
    {
      id: 'nxo92w0Eem',
      fileName: 'test10fail.csv',
      createdBy: '3J9GxxnZ0l',
      createdAt: '2021-11-02 07:10:28.09073 +0000 UTC',
      status: 'IMPORT_DONE',
      totalUserSuccess: '6',
      totalUserFailure: '4',
    },
    {
      id: 'o450Zr0mMG',
      fileName: 'test10fail.csv',
      createdBy: '3J9GxxnZ0l',
      createdAt: '2021-11-02 07:12:34.25192 +0000 UTC',
      status: 'IMPORT_DONE',
      totalUserSuccess: '6',
      totalUserFailure: '4',
    },
    {
      id: 'zQM9Lm0KnW',
      fileName: 'test10fail.csv',
      createdBy: '3J9GxxnZ0l',
      createdAt: '2021-11-02 07:13:03.413961 +0000 UTC',
      status: 'IMPORT_DONE',
      totalUserSuccess: '6',
      totalUserFailure: '4',
    },
  ],
  meta: { limit: 10, offset: 0, total: 14 },
}

export const USER_GROUPS_GET_IMPORT_DETAIL_SUCCESS_RESPONSE = {
  data: [
    {
      email: 'deny.hrnt@gmail.com',
      reason: 'USER_UNDEFINED_REASON',
    },
    {
      email: 'john@gmail.com',
      reason: 'USER_WRONG_FORMAT',
    },
    {
      email: 'janejanejanejane@gmail.com',
      reason: 'USER_SYSTEM_ERROR',
    },
    {
      email: 'jh@gmail.com',
      reason: 'USER_SYSTEM_ERROR',
    },
  ],
  meta: {
    total: 4,
    limit: 10,
    offset: 0,
  },
}

export const USER_GROUPS_GET_ALL_SUCCESS_RESPONSE = {
  data: [
    {
      id: 'xxxxxx1',
      name: 'contributor',
      type: 'GT_UNDEFINED',
    },
    {
      id: 'xxxxxx2',
      name: 'admin',
      type: 'GT_UNDEFINED',
    },
    {
      id: 'xxxxxx3',
      name: 'curator',
      type: 'GT_UNDEFINED',
    },
  ],
  meta: null,
}
