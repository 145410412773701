import create, { State } from 'zustand'
import { persist } from 'zustand/middleware'

import type { AuthError } from 'types/authError'

export interface AuthErrorStates extends State {
  authError?: AuthError | null
  setAuthError: (authError?: AuthError | null) => void
  hasAuthError: () => boolean
}

export const useAuthErrorStore = create<AuthErrorStates>(
  persist(
    (set, get) => ({
      authError: {},
      setAuthError: async (authError: AuthError = {}) =>
        set(() => ({ authError })),
      hasAuthError: (): boolean => Object.keys(get().authError).length > 0,
    }),
    {
      name: 'authError',
      whitelist: ['authError'],
      getStorage: () => sessionStorage,
    }
  )
)
