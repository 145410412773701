import { ReactElement } from 'react'

import { usePermission } from 'utils/hooks/usePermission'

export type AccessControlProps = {
  actions?: string[]
  allowedPermissions?: string[]
  children: ReactElement
  renderNoAccess?: () => ReactElement | null
}

const AccessControl = ({
  actions,
  allowedPermissions,
  children,
  renderNoAccess,
}: AccessControlProps) => {
  const { checkPermissions, currentPathPermissions } = usePermission()

  const permitted = checkPermissions(
    allowedPermissions.length
      ? allowedPermissions
      : currentPathPermissions(actions)
  )

  if (permitted) {
    return children
  }
  return renderNoAccess()
}

AccessControl.defaultProps = {
  allowedPermissions: [],
  renderNoAccess: () => null,
}

export default AccessControl
